<template>
  <div @touchmove.prevent class="LuckyRedBag">
    <template v-if="isFirstModalShow">
      <img class="title" src="@/assets/images/share-landing-v5/lucky-title-bg.png" alt="bg" />
      <div class="info">新人100%提现资格</div>
      <img class="light" src="@/assets/images/share-landing-v5/guang.png" alt="bg" />
      <div class="content">
        <img
          id="bag-close"
          @click="closeFirstModal"
          class="close"
          src="@/assets/images/share-landing-v5/close.png"
          alt="close"
        />
        <div class="content-title">
          <img class="bingo" src="@/assets/images/share-landing-v5/bingo.png" alt="bg" />
          打款金额
        </div>
        <div class="num">
          ¥<span class="active">200</span>
          <div class="tips">最高</div>
        </div>
        <div class="info">
          <div class="info-item">
            <img class="bingo" src="@/assets/images/share-landing-v5/bingo.png" alt="bg" /> 打款申请已提交
          </div>
          <div class="info-item">
            <img class="bingo" src="@/assets/images/share-landing-v5/bingo.png" alt="bg" /> 已发起打款
          </div>
          <div class="info-item active">
            <img class="bingo" src="@/assets/images/share-landing-v5/bingo-ac.png" alt="bg" /> 去快音领取200元
          </div>
        </div>
        <button
          :data-clipboard-text="clipboardText"
          @click="copyDownload('#LuckyRedBagBtn', '去快音领取', pageTitle)"
          class="get-btn"
          id="LuckyRedBagBtn"
        >
          去快音领取
        </button>
      </div>
      <HorizontalScroll :background="`rgba(255, 255, 255, 0.2)`" color="#fff"></HorizontalScroll>
    </template>
    <template v-else>
      <div class="retain-modal">
        <img
          id="retain-close"
          @click="closeRetainModal('关闭')"
          class="close"
          src="@/assets/images/share-landing-v5/close.png"
          alt="close"
        />
        <div class="retain-modal-title">
          <img class="bingo" src="@/assets/images/share-landing-v5/bingo.png" alt="bg" />
          退出后将失去现金
        </div>
        <div class="num">
          ¥<span class="active">200</span>
          <div class="tips">最高</div>
        </div>
        <div class="btn-warp">
          <button @click="closeRetainModal('放弃现金')" id="give-up-btn" class="give-up-btn">放弃现金</button>
          <button
            :data-clipboard-text="clipboardText"
            @click="copyDownload('#retain-get-btn', '挽留弹窗', pageTitle, '去领现金')"
            id="retain-get-btn"
            class="get-btn"
          >
            去领现金
          </button>
        </div>
      </div>
    </template>
    <button
      :data-clipboard-text="clipboardText"
      @click="copyDownload('#v3-download-btn', '固定下载button', pageTitle)"
      id="v3-download-btn"
      class="btn__download"
    >
      点击下载快音 一起听歌赚钱
    </button>
  </div>
</template>

<script>
  import HorizontalScroll from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/HorizontalScroll';
  import { mapGetters } from 'vuex';
  export default {
    name: 'LuckyRedBag',
    components: { HorizontalScroll },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText', 'pageTitle']),
    },
    data() {
      return {
        isFirstModalShow: true,
        firstModalTimeOut: null,
        secondModalTimeOut: null,
      };
    },
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
      resetGame: {
        type: Function,
        default: () => {},
      },
    },
    mounted() {
      this.firstModalTimeOut = setTimeout(() => {
        let downloadDom = document.getElementById('v3-auto-download');
        downloadDom.click();
      }, 5000);
    },
    methods: {
      closeFirstModal() {
        this.$track({
          page_title: this.pageTitle,
          element_name: '关闭按钮',
        });
        clearTimeout(this.firstModalTimeOut);
        this.secondModalTimeOut = setTimeout(() => {
          let downloadDom = document.getElementById('v3-auto-download');
          downloadDom.click();
        }, 5000);
        this.isFirstModalShow = false;
      },
      closeRetainModal(remarks) {
        this.$track({
          page_title: this.pageTitle,
          element_name: '挽留弹窗',
          remarks,
        });
        this.isFirstModalShow = true;
        clearTimeout(this.secondModalTimeOut);
        this.$parent.isLuckyRedBagShow = false;
        this.resetGame();
      },
    },
  };
</script>

<style scoped lang="less">
  .LuckyRedBag {
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      width: 4.78rem;
      height: 0.7rem;
      margin-top: 1.46rem;
    }
    .info {
      font-size: 0.34rem;
      font-weight: bold;
      color: #fff;
      margin-top: 0.1rem;
    }
    .light {
      width: 5.94rem;
      height: 5.9rem;
      position: absolute;
      z-index: -1;
      top: 0.5rem;
    }
    .content {
      border-radius: 0.1rem;
      background: #fff;
      padding: 0.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 6rem;
      margin-top: 0.3rem;
      box-sizing: border-box;
      position: relative;
      .close {
        width: 0.48rem;
        height: 0.48rem;
        position: absolute;
        right: 0.3rem;
        top: 0.3rem;
      }
      .bingo {
        width: 0.36rem;
        height: 0.36rem;
        margin-right: 0.1rem;
      }
      &-title {
        display: flex;
        align-items: center;
        font-size: 0.36rem;
        color: #333;
        font-weight: bold;
      }
      .num {
        font-size: 0.52rem;
        color: #333;
        font-weight: bold;
        margin: 0.14rem 0;
        position: relative;
        .active {
          font-size: 0.72rem;
          margin-left: 0.1rem;
        }
        .tips {
          font-size: 0.22rem;
          padding: 0 0.1rem;
          color: #fff;
          background: #06cb65;
          position: absolute;
          top: 0.04rem;
          right: -0.6rem;
          border-radius: 0.2rem 0.2rem 0.2rem 0;
        }
      }
      .info {
        width: 100%;
        font-size: 0.32rem;
        font-weight: bold;
        color: #06cb65;
        .info-item {
          margin-bottom: 0.4rem;
          display: flex;
          align-items: center;
          &.active {
            color: #fa3123;
          }
        }
      }
      .get-btn {
        border: none;
        width: 5.2rem;
        height: 0.8rem;
        border-radius: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: #fa3123;
        font-size: 0.32rem;
        font-weight: bold;
        animation: btnAni 0.8s linear infinite;
      }
    }
    .retain-modal {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0.1rem;
      background: #fff;
      padding: 0.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 6rem;
      margin-top: 3rem;
      box-sizing: border-box;
      .btn-warp {
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
          width: 2.4rem;
          height: 0.8rem;
          border-radius: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.32rem;
          font-weight: bold;
          border: none;
        }
        .give-up-btn {
          background: #f7f8fa;
          color: #a6a6a6;
        }
        .get-btn {
          color: #fff;
          background: #06cb65;
        }
      }
      .bingo {
        width: 0.36rem;
        height: 0.36rem;
        margin-right: 0.1rem;
      }
      &-title {
        display: flex;
        align-items: center;
        font-size: 0.36rem;
        color: #333;
        font-weight: bold;
      }
      .close {
        width: 0.48rem;
        height: 0.48rem;
        position: absolute;
        right: 0.3rem;
        top: 0.3rem;
      }
      .num {
        font-size: 0.52rem;
        color: #333;
        font-weight: bold;
        margin: 0.3rem 0;
        position: relative;
        .active {
          font-size: 0.72rem;
          margin-left: 0.1rem;
        }
        .tips {
          font-size: 0.22rem;
          padding: 0 0.1rem;
          color: #fff;
          background: #06cb65;
          position: absolute;
          top: 0.04rem;
          right: -0.6rem;
          border-radius: 0.2rem 0.2rem 0.2rem 0;
        }
      }
    }
    .btn__download {
      z-index: 2;
      width: 6.44rem;
      height: 1.1rem;
      background: linear-gradient(180deg, #bbf4ff 0%, #63e0ff 13%, #2989ff 93%, #8bbbff 100%);
      box-shadow: 0 0.07rem 0.11rem 0 #3a2ff1;
      border-radius: 0.55rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.44rem;
      font-weight: bold;
      color: #fff;
      position: fixed;
      bottom: 0.6rem;
      border: none;
      animation: downloadBtnAni 1.5s linear infinite;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  @keyframes btnAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    0% {
      transform: scale(1);
    }
  }
</style>
