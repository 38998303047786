<template>
  <div class="share-landing-modal" @touchmove.prevent>
    <div class="share-landing-modal-content">
      <img
        id="modal-close-btn"
        @click="downLoadApp('#modal-close-btn', '3秒弹窗-关闭')"
        :data-clipboard-text="clipboardText"
        class="share-landing-modal-content-img__close"
        src="@/assets/images/share-content/close.png"
        alt="关闭"
      />
      <img class="share-landing-modal-content-img__get" src="@/assets/images/share-landing-new/get.png" alt="获得" />
      <div class="share-landing-modal-content-info">现金已存入账户</div>
      <div
        @click="downLoadApp('#auto-download-btn', '倒计时结束')"
        id="auto-download-btn"
        ref="autoDownloadBtn"
        :data-clipboard-text="clipboardText"
        class="share-landing-modal-content-download"
      >
        {{ countDownTime }}s后跳转下载页
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'ShareLandingModal',
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
      changeShareLandingModalShow: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        countDownTime: 3,
        countDownInterval: null,
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText']),
    },
    mounted() {
      this.countDownInterval = setInterval(() => {
        if (this.countDownTime > 0) {
          --this.countDownTime;
        }
        if (this.countDownTime === 0) {
          // 自动触发点击事件
          document.getElementById('auto-download-btn').click();
          // this.$refs.autoDownloadBtn.$emit('click');
        }
      }, 1000);
    },
    methods: {
      /**
       * @description 下载
       * @param {String} element 节点ID
       * @param {String} element_name
       */

      downLoadApp(element, element_name) {
        if (this.countDownTime !== 0 && element === '#auto-download-btn') return;
        this.copyDownload(element, element_name);
        this.changeShareLandingModalShow(false);
        clearInterval(this.countDownInterval);
      },
    },
  };
</script>

<style scoped lang="less">
  .share-landing-modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
      background-color: #fff;
      width: 6rem;
      height: 3.48rem;
      border-radius: 0.1rem;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-img__close {
        width: 0.68rem;
        height: 0.68rem;
        position: absolute;
        right: 0;
        top: -1rem;
      }
      &-img__get {
        width: 1.1rem;
        height: 1.1rem;
        margin-top: 0.48rem;
      }
      &-info {
        font-size: 0.36rem;
        font-weight: 600;
        color: #333333;
        margin-top: 0.24rem;
      }
      &-download {
        font-size: 0.32rem;
        font-weight: 400;
        color: #a6a6a6;
        margin-top: 0.24rem;
      }
    }
  }
</style>
