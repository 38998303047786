// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/share-landing/hd-bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/share-landing/main-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "main[data-v-e885da3c]{min-height:100vh;background:linear-gradient(180deg,#ffdbdb,#fc3435);overflow:scroll}main[data-v-e885da3c],main header[data-v-e885da3c]{position:relative}main header[data-v-e885da3c]{width:7.5rem;height:3.43rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;background-size:contain}main header img[id=logo][data-v-e885da3c]{position:absolute;top:.3rem;left:.3rem;width:.7rem;height:.7rem;border-radius:.12rem}main article[data-v-e885da3c]{position:absolute;top:2.84rem;left:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:contain}main article .bottom-text[data-v-e885da3c]{height:1.1rem;font-size:.28rem;font-weight:700;color:#fff;line-height:1.1rem;text-align:center}", ""]);
// Exports
module.exports = exports;
