<template>
  <div
    :class="[
      'share-landing-v3-container-cont',
      isNeedSetBg ? 'active' : '',
      inviteLandingAbV2 === 'g' && !isLanding ? 'abToF' : '',
    ]"
  >
    <HorizontalScroll
      :background="`rgba(250, 50, 36, 0.2)`"
      color="#fa3224"
      v-if="inviteLandingAbV2 === 'g' && !isLanding"
    ></HorizontalScroll>
    <div :class="['share-landing-v3-container-cont-title', inviteLandingAbV2 === 'g' && !isLanding ? 'abToF' : '']">
      <img src="@/assets/images/share-landing-new/icon.png" alt="icon" />
      看看大家的评价
      <img src="@/assets/images/share-landing-new/icon.png" alt="icon" />
    </div>
    <div class="share-landing-v3-container-cont-cont">
      <DiscussContent
        color="#B1453C"
        :background="`linear-gradient(270deg, #FE8F67 0%, #FF8C62 43%, #FFA07D 100%)`"
      ></DiscussContent>
    </div>
  </div>
</template>

<script>
  import DiscussContent from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/DiscussContent';
  import HorizontalScroll from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/HorizontalScroll';
  import { mapGetters } from 'vuex';
  export default {
    name: 'BotContent',
    components: { DiscussContent, HorizontalScroll },
    props: {
      isNeedSetBg: {
        type: Boolean,
        default: true,
      },
      isLanding: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapGetters('share/shareLanding', ['inviteLandingAbV2']),
    },
    data() {
      return {
        discussItemTopDistance: 0,
      };
    },
    mounted() {
      setInterval(() => {}, 1000);
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v3-container-cont {
    height: 8.8rem;
    width: 100%;
    &.abToF {
      height: 9.94rem;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.3rem;
    z-index: 1;
    &.active {
      background: url('~@/assets/images/share-landing-v3/bg-bot.png') no-repeat no-repeat;
      background-size: 100% 100%;
    }
    &-title {
      display: flex;
      align-items: center;
      color: #ff5c18;
      font-size: 0.34rem;
      font-weight: bold;
      margin-top: 1.2rem;
      &.abToF {
        margin-top: 0.3rem;
      }
      img {
        width: 0.66rem;
        height: 0.36rem;
        margin: 0 0.2rem;
      }
    }
    &-cont {
      width: 6.7rem;
      height: 4.5rem;
      background: #ffe7c8;
      border-radius: 0.2rem;
      margin-top: 0.5rem;
    }
  }
</style>
