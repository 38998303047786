<template>
  <div :class="['share-landing-v1', isRegBag ? 'red-bag' : '']">
    <Header :titleUrl="require('@/assets/images/share-landing-v1/invite-font.png')"></Header>
    <FriendGet></FriendGet>
    <div :class="['share-landing-v1-container', !isRegBag ? `containerBg` : '']">
      <div v-if="!isRegBag" class="share-landing-v1-container-glass">
        <img
          ref="price-img"
          class="share-landing-v1-container-glass-img"
          src="@/assets/images/share-landing-v2/price-start.png"
          alt=""
        />
      </div>
      <div v-if="!isRegBag" class="share-landing-v1-container-btn-cont">
        <img
          class="share-landing-v1-container-btn transition"
          src="@/assets/images/share-landing-v1/btn-bg.png"
          alt="btn"
          @click="startLuckyDraw"
        />
        <div class="share-landing-v1-container-btn-cont-count">
          {{ countDownTimer.hour }}:{{ countDownTimer.minutes }}:{{ countDownTimer.seconds }} 即将消失
        </div>
      </div>

      <RedBag
        :tenCountDown="tenCountDown"
        :tenCountDownTimer="tenCountDownTimer"
        :copyDownload="copyDownload"
        :changeRedBagShow="changeRedBagShow"
        v-if="isRegBag"
      ></RedBag>

      <div class="share-landing-v1-container-dis-title">
        <img
          class="share-landing-v1-container-dis-title-start"
          src="@/assets/images/share-landing-v1/left-start.png"
          alt="start"
        />
        看看大家的评价
        <img
          class="share-landing-v1-container-dis-title-start"
          src="@/assets/images/share-landing-v1/right-start.png"
          alt="start"
        />
      </div>
      <div class="share-landing-v1-container-dis">
        <DiscussContent></DiscussContent>
      </div>
    </div>
    <button
      @click="
        copyDownload(
          '#v1-download-btn',
          '点击下载快音，一起听歌赚钱',
          isRegBag ? '落地页常态优化A-1' : '落地页常态优化A'
        )
      "
      id="v1-download-btn"
      class="share-landing-v1-btn__download"
      :data-clipboard-text="clipboardText"
    >
      点击下载快音 一起听歌赚钱
    </button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import FriendGet from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/FriendGet';
  import DiscussContent from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/DiscussContent';
  import RedBag from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/RedBag';
  import Header from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/Header';

  export default {
    name: 'ShareLandingV1',
    components: { RedBag, FriendGet, DiscussContent, Header },
    props: {
      countDownTimer: {
        type: Object,
        default: () => {},
      },
      copyDownload: {
        type: Function,
        default: () => {},
      },
      changeRedBagShow: {
        type: Function,
        default: () => {},
      },
      isRegBag: {
        type: Boolean,
        default: false,
      },
      tenCountDown: {
        type: Function,
        default: () => {},
      },
      tenCountDownTimer: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters('share/shareLanding', [
        'clipboardText',
        'userInfo',
        'inviteLandingAb',
        'inviteCode',
        'clipboardText',
        'inviteLandingAb',
        'showMaxReward',
      ]),
    },
    data() {
      return {
        priceImg: null,
        priceBg: require('@/assets/images/share-landing-v2/price-bg.gif'),
        priceLast: require('@/assets/images/share-landing-v2/price-last.png'),
      };
    },
    mounted() {
      this.priceImg = this.$refs['price-img'];
    },
    methods: {
      startLuckyDraw() {
        this.priceImg.src = this.priceBg;
        this.$track({
          page_title: '落地页常态优化A',
          element_name: '抽奖',
        });
        setTimeout(() => {
          this.changeRedBagShow(true);
        }, 4500);
      },
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v1 {
    user-select: none;
    background: url('~@/assets/images/share-landing-v1/all-bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.red-bag {
      background: url('~@/assets/images/share-landing-v1/red-price-bg.png') no-repeat no-repeat;
      background-size: 100% 100%;
      //min-height: 100vh;
      height: 18.65rem;
    }
    &-container {
      height: 15.39rem;
      width: 100%;
      margin-top: 0.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-btn-cont {
        position: relative;
        &-count {
          position: absolute;
          top: -0.5rem;
          right: 0;
          width: 2.44rem;
          height: 0.62rem;
          background: url('~@/assets/images/share-landing-v1/count-down-bg.png') no-repeat no-repeat;
          background-size: 100% 100%;
          font-size: 0.24rem;
          color: #ec5727;
          text-align: center;
          font-weight: bold;
          line-height: 0.46rem;
        }
      }
      &.containerBg {
        background: url('~@/assets/images/share-landing-v1/price-bg.png') no-repeat no-repeat;
        background-size: 100% 100%;
      }
      &-glass {
        background: url('~@/assets/images/share-landing-v1/glass.png') no-repeat no-repeat;
        background-size: 100% 100%;
        width: 6.9rem;
        height: 2.76rem;
        margin-top: 2.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &-img {
          width: 5.9rem;
          height: 2.3rem;
        }
      }
      &-btn {
        width: 5.16rem;
        height: 1.66rem;
        margin-top: 0.2rem;
        &.transition {
          animation: priceBtnAni linear 1s infinite;
        }
      }
      &-dis {
        width: 6.9rem;
        height: 4.4rem;
        border-radius: 0.1rem;
        background: #e62307;
        margin-top: 0.3rem;
        &-title {
          display: flex;
          align-items: center;
          color: #fedfaf;
          font-size: 0.32rem;
          font-weight: bold;
          margin-top: 0.74rem;
          &-start {
            width: 0.62rem;
            height: 0.28rem;
            margin: 0 0.2rem;
          }
        }
      }
    }
    &-btn__download {
      width: 6.44rem;
      height: 1.1rem;
      background: linear-gradient(180deg, #bbf4ff 0%, #63e0ff 13%, #2989ff 93%, #8bbbff 100%);
      box-shadow: 0 0.07rem 0.11rem 0 #3a2ff1;
      border-radius: 0.55rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.44rem;
      font-weight: bold;
      color: #fff;
      position: fixed;
      bottom: 0.6rem;
      border: none;
      animation: priceBtnAni 1.5s linear infinite;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  @keyframes priceBtnAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
