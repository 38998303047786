<template>
  <div class="dis-warp" ref="scroll">
    <div
      v-for="(item, k) in discussList"
      :key="k"
      :class="['dis-warp-item', isNeedTransition ? 'item-transition' : '']"
      :style="`top: -${discussItemTopDistance}rem`"
    >
      <img class="dis-warp-item-img__author" :src="item.avatar_small" alt="头像" />
      <div :class="['dis-warp-item-info']" :style="{ background, color }">
        <label :class="['dis-warp-item-info-icon']" :style="{ borderRight: `0.1rem solid ${background}` }"></label>
        <span class="dis-warp-item-info-cont">{{ item.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'DiscussContent',
    computed: {
      ...mapGetters('share/shareLanding', ['evaluation']),
    },
    props: {
      background: {
        type: String,
        default: '#ff502d',
      },
      color: {
        type: String,
        default: '#fedfaf',
      },
    },
    data() {
      return {
        scrollInterval: null,
        discussItemTopDistance: 0, //评论内容偏移值
        isNeedTransition: true, //是否需要过渡动画
        reSetScrollFlag: 0,
        scrollTimer: 0,
        discussList: [],
      };
    },
    beforeMount() {
      // 滚动到列表长度
      this.reSetScrollFlag = this.evaluation.length;
      this.discussList = [...this.evaluation, ...this.evaluation];
      this.discussScrollInterval();
    },
    methods: {
      /**
       * @description 评论区滚动效果
       */

      discussScrollInterval() {
        this.scrollInterval = setInterval(() => {
          if (this.scrollTimer === this.reSetScrollFlag) {
            // 暂停过渡效果
            this.isNeedTransition = false;
            // 恢复评论区初始位置
            this.discussItemTopDistance = 0;
            this.scrollTimer = 0;
            // 开启过渡效果
            setTimeout(() => {
              this.isNeedTransition = true;
              this.discussItemTopDistance += 1.1;
              this.scrollTimer++;
            }, 100);
            return;
          }
          // 偏移值递增
          this.discussItemTopDistance += 1.1;
          this.scrollTimer++;
        }, 1500);
        this.$once('hook:beforeDestroy', () => {
          clearInterval(this.scrollInterval);
        });
      },
    },
  };
</script>

<style scoped lang="less">
  .dis-warp {
    height: 4.4rem;
    overflow: hidden;
    padding: 0 0.4rem;
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0.3rem 0;
      &.item-transition {
        transition: top 1s;
      }
      &-info {
        padding: 0 0.2rem;
        //color: #fedfaf;
        font-size: 0.28rem;
        font-weight: 600;
        border-radius: 0.1rem;
        margin-left: 0.5rem;
        height: 0.8rem;
        display: flex;
        align-items: center;
        position: relative;
        //background: #ff502d;
        &-cont {
          display: inline-block;
          max-width: 4.3rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &-icon {
          display: block;
          position: absolute;
          left: -0.1rem;
          top: 0.4rem;
          transform: translateY(-0.05rem);
          width: 0;
          height: 0;
          border-top: 0.1rem solid transparent;

          border-bottom: 0.1rem solid transparent;
          //border-right: 0.1rem solid #ff502d;
        }
      }
      &-img__author {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
      }
    }
  }
</style>
