// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/share-landing-v2/bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/share-landing-v2/red-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".share-landing-v2[data-v-14ecda16]{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;min-height:100vh;display:flex;flex-direction:column;align-items:center}.share-landing-v2.red-bag[data-v-14ecda16]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100% 100%}.share-landing-v2-container[data-v-14ecda16]{height:15.39rem;margin-top:.2rem}.share-landing-v2-container[data-v-14ecda16],.share-landing-v2-container-dis[data-v-14ecda16]{width:100%;display:flex;flex-direction:column;align-items:center}.share-landing-v2-container-dis[data-v-14ecda16]{height:10.83rem}.share-landing-v2-container-dis-title[data-v-14ecda16]{display:flex;align-items:center;color:#ff5c18;font-size:.34rem;font-weight:700;margin-top:.8rem}.share-landing-v2-container-dis-title img[data-v-14ecda16]{width:.66rem;height:.36rem;margin:0 .2rem}.share-landing-v2-container-dis-cont[data-v-14ecda16]{width:6.7rem;height:4.5rem;background:linear-gradient(180deg,#fbe4bf,#fcb377 64%,#ffbf89);border-radius:.2rem;margin-top:.5rem}.share-landing-v2-btn__download[data-v-14ecda16]{width:6.44rem;height:1.1rem;background:linear-gradient(180deg,#bbf4ff,#63e0ff 13%,#2989ff 93%,#8bbbff);box-shadow:0 .07rem .11rem 0 #3a2ff1;border-radius:.55rem;display:flex;justify-content:center;align-items:center;font-size:.44rem;font-weight:700;color:#fff;position:fixed;bottom:.6rem;border:none;-webkit-animation:downloadBtnAni-data-v-14ecda16 1.5s linear infinite;animation:downloadBtnAni-data-v-14ecda16 1.5s linear infinite}.share-landing-v2-btn__download[data-v-14ecda16]:active{transform:scale(.9)}@-webkit-keyframes downloadBtnAni-data-v-14ecda16{0%{transform:scale(1)}50%{transform:scale(.95)}to{transform:scale(1)}}@keyframes downloadBtnAni-data-v-14ecda16{0%{transform:scale(1)}50%{transform:scale(.95)}to{transform:scale(1)}}", ""]);
// Exports
module.exports = exports;
