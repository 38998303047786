<template>
  <div class="botContent">
    <div :class="['share-landing-v5-container-cont-title']">
      <img src="@/assets/images/share-landing-new/icon.png" alt="icon" />
      看看大家的评价
      <img src="@/assets/images/share-landing-new/icon.png" alt="icon" />
    </div>
    <div class="share-landing-v5-container-cont-cont">
      <DiscussContent
        color="#B1453C"
        :background="`linear-gradient(270deg, #FE8F67 0%, #FF8C62 43%, #FFA07D 100%)`"
      ></DiscussContent>
    </div>
  </div>
</template>

<script>
  import DiscussContent from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/DiscussContent';
  export default {
    name: 'BotContent',
    components: { DiscussContent },
  };
</script>

<style scoped lang="less">
  .botContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .share-landing-v5-container-cont-title {
    display: flex;
    align-items: center;
    color: #ff5c18;
    font-size: 0.34rem;
    font-weight: bold;
    margin-top: 0.5rem;

    img {
      width: 0.66rem;
      height: 0.36rem;
      margin: 0 0.2rem;
    }
  }
  .share-landing-v5-container-cont-cont {
    width: 6.7rem;
    height: 4.5rem;
    background: #ffe7c8;
    border-radius: 0.2rem;
    margin-top: 0.5rem;
  }
</style>
