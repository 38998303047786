<template>
  <div class="contentWarp">
    <img class="content-bg" src="@/assets/images/share-landing-v5/content-bg.png" alt="bg" />
    <img v-show="isGiftShow" ref="imgGif" class="img-gif" :src="gifSrc + `?${Math.random()}`" alt="" />
    <div class="dun-warp">
      <img :style="iconStyle" class="icon-dun" src="@/assets/images/share-landing-v5/icon-dun.png" alt="dun" />
      <img :style="giftStyle" class="gift-dun" src="@/assets/images/share-landing-v5/gift-dun.png" alt="dun" />
      <img :style="redStyle" class="bag-dun" src="@/assets/images/share-landing-v5/bag-dun.png" alt="dun" />
      <img class="start-dun" src="@/assets/images/share-landing-v5/start-dun.png" alt="dun" />
      <img v-show="staticJumpMan" ref="jumpMan" class="man" src="@/assets/images/share-landing-v5/man.png" alt="dun" />
    </div>
    <div v-show="!staticJumpMan" class="jump-person">
      <lottie @animCreated="handleAnimation" :options="marqueeOptions" />
    </div>
    <div class="btn-warp">
      <div class="info">跳得越远，红包越大</div>
      <button id="jump-btn" @click="startJump" class="jump-btn">点击跳一跳</button>
      <img v-if="isHandShow" class="pointer" src="@/assets/images/share-landing-v5/hand@2x.png" alt="bg" />
    </div>
    <audio loop ref="audio">
      <source src="@/assets/mp3/icon.mp3" />
    </audio>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import lottie from '@/components/Lottie/index.js';
  import personJump from '@/assets/lottie/person-jump.json';

  export default {
    name: 'ContentWarp',
    components: {
      lottie: lottie,
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText', 'inviteLandingAbV2', 'selectChildIndex', 'pageTitle']),
      iconStyle() {
        return this.selectChildIndex === 1
          ? `top:${this.iconPositionInfo[0].top};left:${this.iconPositionInfo[0].left}`
          : `top:${this.iconPositionInfo[1].top};left:${this.iconPositionInfo[1].left}`;
      },
      giftStyle() {
        if (this.selectChildIndex === 2) {
          return `top:${this.giftPositionInfo[0].top};left:${this.giftPositionInfo[0].left}`;
        } else if (this.selectChildIndex === 3) {
          return `top:${this.giftPositionInfo[2].top};left:${this.giftPositionInfo[2].left}`;
        }
        return `top:${this.giftPositionInfo[1].top};left:${this.giftPositionInfo[1].left}`;
      },
      redStyle() {
        return this.selectChildIndex === 3
          ? `top:${this.redPositionInfo[0].top};left:${this.redPositionInfo[0].left}`
          : `top:${this.redPositionInfo[1].top};left:${this.redPositionInfo[1].left}`;
      },
      gifSrc() {
        return this.selectChildIndex === 1 ? this.iconUrl : this.redBagUrl;
      },
    },
    props: {
      changeBestLuckModalShow: {
        type: Function,
        default: () => {},
      },
      copyDownload: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        jumpPersonAni: {},
        staticJumpMan: true,
        redBagUrl: require('@/assets/images/share-landing-v5/red-bag.gif'),
        iconUrl: require('@/assets/images/share-landing-v5/icon.gif'),
        isHandShow: true,
        isGiftShow: false,
        marqueeOptions: {
          animationData: personJump,
          loop: false,
          autoplay: false,
        },
        iconPositionInfo: [
          { top: '1.3rem', left: '1.2rem' },
          { top: '2.3rem', left: '4.8rem' },
        ],
        giftPositionInfo: [
          { top: '1.1rem', left: '0.8rem' },
          { top: '2.3rem', left: '4.4rem' },
          { top: '3.6rem', left: '0.8rem' },
        ],
        redPositionInfo: [
          { top: '1.3rem', left: '0.8rem' },
          { top: '3.5rem', left: '0.5rem' },
        ],
      };
    },
    mounted() {
      let svgDom = document.getElementById('lavContainer').firstChild;
      svgDom.style.width = '7.5rem';
      this.jumpPersonAni.addEventListener('complete', () => {
        this.isGiftShow = true;
        this.playOrPauseMusic(true);
        setTimeout(() => {
          this.changeBestLuckModalShow(true);
          this.playOrPauseMusic(false); // 暂停音乐
          this.isGiftShow = false; // 展示奖励gif
          this.isHandShow = true; // 显示点击手指
          this.jumpPersonAni.stop(); //回到第一帧
          this.staticJumpMan = true;
          this.getSelectIndex();
        }, 1800);
      });
    },
    methods: {
      ...mapMutations('share/shareLanding', ['SET_SELECT_CHILD_INDEX']),
      getSelectIndex() {
        let arr = [1, 2, 3];
        let index = Math.floor(Math.random() * arr.length);
        this.SET_SELECT_CHILD_INDEX(arr[index]);
      },
      startJump() {
        if (this.isHandShow) {
          this.$track({
            page_title: this.pageTitle,
            element_name: '我来试一试',
          });
          this.isHandShow = false;
          this.staticJumpMan = false;
          this.jumpPersonAni.play();
        }
      },
      playOrPauseMusic(flag) {
        if (flag) {
          this.$refs.audio.play();
        } else {
          this.$refs.audio.currentTime = 0; // 重新播放
          this.$refs.audio.pause();
        }
      },
      handleAnimation(anim) {
        this.jumpPersonAni = anim;
      },
    },
  };
</script>

<style scoped lang="less">
  .contentWarp {
    width: 100%;
  }
  .jump-person {
    position: absolute;
    top: 0;
  }
  .img-gif {
    width: 100%;
    position: absolute;
    z-index: 9;
  }
  .content-bg {
    width: 7.5rem;
    height: 7.5rem;
    position: absolute;
    top: 0;
  }
  .dun-warp {
    position: relative;
    height: 6.6rem;
    width: 100%;
  }
  .icon-dun {
    width: 1.96rem;
    height: 1.48rem;
    position: absolute;
    //top: 1.3rem;
    //left: 1.2rem;
  }
  .gift-dun {
    width: 2.42rem;
    height: 1.96rem;
    position: absolute;
    //top: 2.3rem;
    //right: 1.1rem;
  }
  .bag-dun {
    width: 2.42rem;
    height: 1.96rem;
    position: absolute;
    //top: 3.3rem;
    //left: 0.5rem;
  }
  .start-dun {
    width: 1.94rem;
    height: 1.58rem;
    position: absolute;
    top: 5rem;
    right: 2.1rem;
  }
  .man {
    width: 1.04rem;
    height: 1.72rem;
    position: absolute;
    top: 3.86rem;
    right: 2.2rem;
    &.jumpToReg {
      animation: toRegDunAni 0.5s 1 linear forwards;
    }
    &.jumpToIcon {
      animation: toIconDunAni 0.6s 1 linear forwards;
    }
    &.jumpToGift {
      animation: toGiftDunAni 0.6s 1 linear forwards;
    }
    //top: 2.4rem;
    //right: 4.7rem;
    //top: 1.3rem;
    //right: 1.4rem;
  }
  .btn-warp {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .pointer {
      width: 1.1rem;
      height: 1.2rem;
      position: absolute;
      right: 1.2rem;
      bottom: -0.4rem;
      animation: pointerAni 0.5s linear infinite;
    }
    .info {
      font-size: 0.3rem;
      font-weight: bold;
      color: #ff4327;
      margin: 0.2rem 0;
    }
    .jump-btn {
      width: 5.2rem;
      height: 1.18rem;
      border-radius: 0.59rem;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0.07rem 0.11rem 0 rgba(241, 47, 47, 0.39);
      font-size: 0.5rem;
      font-weight: bold;
      background: #ff2f2f;
      color: #fff;
      border: none;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  @keyframes pointerAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
