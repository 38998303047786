<template>
  <div>
    <div :class="['share-landing-v5', isBestLuckModalShow && 'filter']">
      <Header
        :height="0.59"
        :width="6.14"
        :titleUrl="require('@/assets/images/share-landing-v5/title-bg.png')"
      ></Header>
      <ContentWarp :copyDownload="copyDownload" :changeBestLuckModalShow="changeBestLuckModalShow"></ContentWarp>
      <BotContent></BotContent>

      <button
        id="v3-download-btn"
        @click="copyDownload('#v2-download-btn', '固定下载button', pageTitle)"
        :data-clipboard-text="clipboardText"
        class="share-landing-v5-btn__download"
      >
        点击下载快音 一起听歌赚钱
      </button>
      <div
        id="v5-auto-download"
        ref="autoDownload"
        @click="copyDownload('#v5-auto-download', '自动跳转', pageTitle)"
        :data-clipboard-text="clipboardText"
      ></div>
    </div>

    <div @touchmove.prevent v-if="isBestLuckModalShow" class="bestLuck-modal">
      <img
        id="bestLuck-modal-close"
        @click="copyDownload('#bestLuck-modal-close', '关闭按钮', pageTitle)"
        :data-clipboard-text="clipboardText"
        class="close"
        src="@/assets/images/share-landing-v5/close.png"
        alt="close"
      />
      <img class="price-bg" src="@/assets/images/share-landing-v5/price-bg.png" alt="" />
      <button
        id="immediate-btn"
        @click="copyDownload('#immediate-btn', '立即去领', pageTitle)"
        :data-clipboard-text="clipboardText"
        class="immediate-btn"
      >
        立即去领
      </button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Header from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/Header';
  import ContentWarp from '@/views/Welfare/Fission/ShareLanding/ShareLandingV5/components/ContentWarp';
  import BotContent from '@/views/Welfare/Fission/ShareLanding/ShareLandingV5/components/BotContent';

  export default {
    name: 'index',
    components: { Header, ContentWarp, BotContent },
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        isBestLuckModalShow: false,
        downloadTimeout: null,
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText', 'inviteLandingAbV2', 'pageTitle']),
    },
    methods: {
      changeBestLuckModalShow(flag) {
        this.isBestLuckModalShow = flag;
        if (flag) {
          this.downloadTimeout = setTimeout(() => {
            this.$refs.autoDownload.click();
          }, 6000);
        } else {
          this.$track({
            page_title: this.pageTitle,
            element_name: '关闭按钮',
          });
          clearTimeout(this.downloadTimeout);
        }
      },
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v5 {
    user-select: none;
    height: 18.44rem;
    background: linear-gradient(180deg, #ffb29c 0%, #ffd8be 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &.filter {
      filter: blur(5px);
    }

    &-btn__download {
      z-index: 2;
      width: 6.44rem;
      height: 1.1rem;
      background: linear-gradient(180deg, #bbf4ff 0%, #63e0ff 13%, #2989ff 93%, #8bbbff 100%);
      box-shadow: 0 0.07rem 0.11rem 0 #3a2ff1;
      border-radius: 0.55rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.44rem;
      font-weight: bold;
      color: #fff;
      position: fixed;
      bottom: 0.6rem;
      border: none;
      animation: downloadBtnAni 1.5s linear infinite;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  .bestLuck-modal {
    background: rgba(0, 0, 0, 0.75);
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    .price-bg {
      width: 7.5rem;
      height: 9.8rem;
    }
    .close {
      width: 0.48rem;
      height: 0.48rem;
      position: absolute;
      right: 0.6rem;
      top: 3rem;
    }
    .immediate-btn {
      width: 3.96rem;
      height: 1.16rem;
      border-radius: 0.58rem;
      border: none;
      color: #ef2222;
      font-size: 0.52rem;
      background: #ffecb1;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: btnAni 1s linear infinite;
    }
  }
  @keyframes btnAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
