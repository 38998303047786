<template>
  <main>
    <header>
      <img id="logo" src="@/assets/images/share-landing/hd-logo.png" alt="logo" />
      <img src="@/assets/images/share-landing/hd-banner.png" alt="头图" />
    </header>
    <article>
      <MusicList :copy-download="copyDownload" />
      <DownloadButton :copy-download="copyDownload" />
      <RedEnvelope :copy-download="copyDownload" />
      <div class="bottom-text">来快音，让音乐陪伴你</div>
    </article>
  </main>
</template>

<script>
  import MusicList from './MusicList';
  import DownloadButton from './DownloadButton';
  import RedEnvelope from './RedEnvelope';
  export default {
    components: { MusicList, DownloadButton, RedEnvelope },
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>

<style lang="less" scoped>
  main {
    position: relative;
    min-height: 100vh;
    background: linear-gradient(180deg, #ffdbdb 0%, #fc3435 100%);
    overflow: scroll;
    header {
      position: relative;
      width: 7.5rem;
      height: 3.43rem;
      background: url('~@/assets/images/share-landing/hd-bg.png') center center no-repeat;
      background-size: contain;
      img[id='logo'] {
        position: absolute;
        top: 0.3rem;
        left: 0.3rem;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 0.12rem;
      }
    }
    article {
      position: absolute;
      top: 2.84rem;
      left: 0;
      background: url('~@/assets/images/share-landing/main-bg.png') no-repeat center center;
      background-size: contain;
      .bottom-text {
        height: 1.1rem;
        font-size: 0.28rem;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.1rem;
        text-align: center;
      }
    }
  }
</style>
