<template>
  <div class="need-open-red-bag">
    <div class="need-open-red-bag-title">恭喜发财</div>
    <div class="need-open-red-bag-name">
      <div
        :class="['need-open-red-bag-name-item', isNeedTransition ? 'item-transition' : '']"
        :style="`top:-${friendItemTopDistance}rem`"
        v-for="(item, k) in friendGetList"
        :key="k"
      >
        {{ item }}
      </div>
    </div>
    <div class="need-open-red-bag-time-warp">
      <div class="need-open-red-bag-time-warp-item">{{ countDownTimer.hour }}</div>
      :
      <div class="need-open-red-bag-time-warp-item">{{ countDownTimer.minutes }}</div>
      :
      <div class="need-open-red-bag-time-warp-item">{{ countDownTimer.seconds }}</div>
    </div>
    <img
      @click="openRedBag"
      class="need-open-red-bag-img__open"
      src="@/assets/images/share-landing-new/open.png"
      alt="开红包"
    />
    <div class="need-open-red-bag-code">
      <div>邀请码：{{ inviteCode }}</div>
      <div
        @click="copyInviteCode('#need-open-btn-copy', true)"
        :data-clipboard-text="clipboardText"
        id="need-open-btn-copy"
        class="need-open-red-bag-code-btn__copy"
      >
        复制
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'NeedOpenRedBag',
    props: {
      openRedBag: {
        type: Function,
        default: () => {},
      },
      countDownTimer: {
        type: Object,
        default: () => {},
      },
      copyInviteCode: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters('share/shareLanding', ['inviteCode', 'clipboardText', 'shufflingInfo']),
    },
    filters: {
      /**
       * @description 补零
       * @param { Number } num 传入需补零数字
       */
      addAero(num) {
        return num < 10 ? `0${num}` : num;
      },
    },
    data() {
      return {
        friendGetList: [],
        friendItemTopDistance: 0,
        isNeedTransition: true,
        reSetScrollFlag: 0,
        scrollTimer: 0,
      };
    },
    created() {},
    beforeMount() {
      this.reSetScrollFlag = this.shufflingInfo.length;
      if (this.reSetScrollFlag > 0) {
        this.friendGetList = [...this.shufflingInfo, this.shufflingInfo[0]];
        this.friendItemScroll();
      }
    },
    mounted() {},
    methods: {
      /**
       * @description 好友获得滚动
       */

      friendItemScroll() {
        setInterval(() => {
          if (this.reSetScrollFlag === this.scrollTimer) {
            // 暂停过渡效果
            this.isNeedTransition = false;
            // 恢复滚动区初始位置
            this.friendItemTopDistance = 0;
            this.scrollTimer = 0;
            // 开启过渡效果
            setTimeout(() => {
              this.isNeedTransition = true;
              this.friendItemTopDistance += 0.5;
              this.scrollTimer++;
            }, 100);
            return;
          }
          // 偏移值递增
          this.friendItemTopDistance += 0.5;
          this.scrollTimer++;
        }, 1500);
      },
    },
  };
</script>

<style scoped lang="less">
  .need-open-red-bag {
    background: url('~@/assets/images/share-landing-new/red-other-bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    height: 8.6rem;
    width: 100%;
    position: relative;
    top: -0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: 0.6rem;
      font-weight: 600;
      background-image: -webkit-linear-gradient(#ffdcc2, #ffae71);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 1.5rem;
    }
    &-name {
      font-size: 0.32rem;
      font-weight: 600;
      height: 0.5rem;
      color: #ffdcc2;
      margin-top: 0.2rem;
      overflow: hidden;
      &-item {
        position: relative;
        text-align: center;
        height: 0.5rem;
        justify-content: center;
        display: flex;
        align-items: center;
        &.item-transition {
          transition: top 1s;
        }
      }
    }
    &-time-warp {
      display: flex;
      color: #fff;
      align-items: center;
      font-weight: bold;
      margin-top: 0.3rem;
      font-size: 0.24rem;
      &-item {
        width: 0.46rem;
        height: 0.46rem;
        margin: 0 0.1rem;
        background: linear-gradient(#ffd7b9, #ffb47c);
        font-size: 0.3rem;
        font-weight: bold;
        color: #b94f10;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.06rem;
      }
    }
    &-img__open {
      width: 2.4rem;
      height: 2.4rem;
      margin-top: 0.2rem;
      animation: openRedBagAni 1.5s infinite linear;
    }
    &-code {
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0.04rem;
      font-size: 0.24rem;
      color: #ffffff;
      padding: 0.04rem 0.1rem;
      margin-top: 0.4rem;
      &-btn__copy {
        width: 0.6rem;
        height: 0.28rem;
        border-radius: 0.14rem;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.18rem;
        margin-left: 0.16rem;
      }
    }
  }
  @keyframes openRedBagAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
