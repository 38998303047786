<template>
  <div class="main">
    <ul>
      <header v-if="userInfo && userInfo.role === 'normal'">
        <img :src="userInfo.avatar_small" alt="avatar_small" />
        <h5 class="one-line-ellipsis highlight">{{ userInfo.nickname }}</h5>
        <p class="highlight">邀请你一起来快音听热歌</p>
      </header>
      <li v-for="(music, index) in musicList" :key="index">
        <button
          :id="`ml-btn${index}`"
          @click="copyDownload(`#ml-btn${index}`, '音乐列表')"
          :data-clipboard-text="clipboardText"
        >
          <div class="cover">
            <img :src="music.cover" alt="cover" />
          </div>
          <h5 class="one-line-ellipsis">{{ music.title }}</h5>
          <div class="bottom-wrapper">
            <div class="time">
              <i class="time-icon" />
              <span>{{ music.play_time | formatTime }}</span>
            </div>
            <div class="tag one-line-ellipsis" v-if="music.tags.length !== 0">
              <p>{{ music.tags[0].name }}</p>
            </div>
            <div class="topic" v-if="music.show_text">
              <p class="highlight one-line-ellipsis">#{{ music.show_text }}#</p>
            </div>
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
  import { formatTime } from '@/utils/common';
  import { mapGetters } from 'vuex';

  export default {
    name: 'MusicList',
    components: {},
    props: {
      copyDownload: {
        type: Function,
      },
    },
    data() {
      return {};
    },
    filters: {
      formatTime: formatTime,
    },
    computed: {
      ...mapGetters('share/shareLanding', ['userInfo', 'inviteCode', 'clipboardText', 'musicList']),
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style lang="less" scoped>
  .main {
    overflow: hidden;
    padding: 0 0.52rem;
    ul {
      header {
        display: flex;
        align-items: center;
        height: 0.78rem;
        border-top-left-radius: 0.24rem;
        border-top-right-radius: 0.24rem;
        img {
          margin: 0 0.24rem;
          width: 0.48rem;
          height: 0.48rem;
          border-radius: 0.24rem;
        }
        h5 {
          margin-right: 0.12rem;
          max-width: 2rem;
          font-size: 0.32rem;
        }
        p {
          font-size: 0.24rem;
          font-weight: normal;
        }
      }
      background-color: #fff;
      border-radius: 0.24rem;
      li {
        position: relative;
        padding: 0.24rem;
        width: 5.92rem;
        height: 0.9rem;
        button {
          border: none;
          .cover {
            position: relative;
            width: 0.9rem;
            height: 0.9rem;
            img {
              width: 0.9rem;
              height: 0.9rem;
              border-radius: 0.12rem;
            }
            &::after {
              content: '';
              position: absolute;
              right: 0.08rem;
              bottom: 0.08rem;
              width: 0.28rem;
              height: 0.28rem;
              background: url('~@/assets/images/share-landing/icon-play.png') no-repeat center center;
              background-size: contain;
            }
          }
          h5 {
            position: absolute;
            top: 0.3rem;
            left: 1.38rem;
            width: 4.63rem;
            font-size: 0.3rem;
            text-align: left;
          }
          .bottom-wrapper {
            position: absolute;
            left: 1.38rem;
            bottom: 0.26rem;
            width: 4.63rem;
            height: 0.3rem;
            display: flex;
            align-items: center;
            .time {
              padding: 0 0.08rem;
              height: 0.3rem;
              display: flex;
              align-items: center;
              margin-right: 0.1rem;
              border-radius: 4px;
              background: #f9f9f9;
              .time-icon {
                margin-right: 0.04rem;
                width: 0.2rem;
                height: 0.2rem;
                border-radius: 0.1rem;
                background: url('~@/assets/images/share-landing/icon-time.png') no-repeat center center;
                background-size: contain;
              }
              span {
                font-size: 0.18rem;
                color: #bbbbbb;
              }
            }
            .tag {
              padding: 0 0.08rem;
              height: 0.3rem;
              display: flex;
              max-width: 1.2rem;
              align-items: center;
              margin-right: 0.1rem;
              border-radius: 4px;
              background: #f9f9f9;
              p {
                font-size: 0.18rem;
                color: #bbbbbb;
              }
            }
            .topic {
              max-width: 2.3rem;
              font-size: 0.2rem;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
  .highlight {
    color: #e50505;
  }
</style>
