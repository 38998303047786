<template>
  <div class="HorizontalScroll">
    <div
      :style="`left: -${discussItemTopDistance}rem;background:${background};color:${color}`"
      :class="['friend-get-item', isNeedTransition ? 'item-transition' : '']"
      v-for="(item, k) in discussList"
      :key="k"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'HorizontalScroll',
    computed: {
      ...mapGetters('share/shareLanding', ['marqueeFriend']),
    },
    props: {
      background: {
        type: String,
        default: '#ff502d',
      },
      color: {
        type: String,
        default: '#fedfaf',
      },
    },
    data() {
      return {
        scrollInterval: null,
        discussItemTopDistance: 0, //评论内容偏移值
        isNeedTransition: true, //是否需要过渡动画
        reSetScrollFlag: 0,
        scrollTimer: 0,
        discussList: [],
      };
    },
    beforeMount() {
      // 滚动到列表长度
      this.reSetScrollFlag = this.marqueeFriend.length;
      this.discussList = [...this.marqueeFriend, ...this.marqueeFriend];
      this.discussScrollInterval();
    },
    methods: {
      /**
       * @description 评论区滚动效果
       */

      discussScrollInterval() {
        this.scrollInterval = setInterval(() => {
          if (this.scrollTimer === this.reSetScrollFlag) {
            // 暂停过渡效果
            this.isNeedTransition = false;
            // 恢复评论区初始位置
            this.discussItemTopDistance = 0;
            this.scrollTimer = 0;
            // 开启过渡效果
            setTimeout(() => {
              this.isNeedTransition = true;
              this.discussItemTopDistance += 3.3;
              this.scrollTimer++;
            }, 100);
            return;
          }
          // 偏移值递增
          this.discussItemTopDistance += 3.3;
          this.scrollTimer++;
        }, 2000);
        this.$once('hook:beforeDestroy', () => {
          clearInterval(this.scrollInterval);
        });
      },
    },
  };
</script>

<style scoped lang="less">
  .HorizontalScroll {
    width: 100%;
    overflow-x: hidden;
    margin-top: 1.4rem;
    display: flex;

    .friend-get-item {
      &.item-transition {
        transition: left 1.5s;
      }
      .avatar {
        width: 0.36rem;
        height: 0.36rem;
        border-radius: 50%;
      }
      position: relative;
      font-size: 0.24rem;
      flex-shrink: 0;
      width: 2.7rem;
      height: 0.48rem;
      padding: 0 0.1rem;
      box-sizing: border-box;
      border-radius: 0.24rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin: 0 0.3rem;
    }
  }
</style>
