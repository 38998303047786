<template>
  <div class="share-landing-v4-container-redBag">
    <BookContent :top="0.5" :left="0.34"></BookContent>
    <div class="share-landing-v4-container-redBag-box">
      <img
        @click="copyDownload('#v4-giveMe-btn-two', '没问题a', '落地页搞笑')"
        id="v4-giveMe-btn-two"
        class="share-landing-v4-container-redBag-btn"
        src="@/assets/images/share-landing-v4/btn-3.png"
        alt="btn"
      />
      <img class="hand" src="@/assets/images/share-landing-v4/pointer.png" alt="hand" />
    </div>
    <img
      id="v4-close-btn"
      @click="copyDownload('#v4-close-btn', '关闭', '落地页搞笑')"
      class="close"
      src="@/assets/images/share-landing-v3/close.png"
      alt="close"
    />
    <div
      id="v4-auto-download"
      ref="autoDownload"
      @click="copyDownload('#v4-auto-download', '自动跳转', '落地页搞笑')"
      :data-clipboard-text="clipboardText"
    ></div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import BookContent from '@/views/Welfare/Fission/ShareLanding/ShareLandingV4/components/BookContent';
  export default {
    name: 'RedBag',
    components: { BookContent },
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText']),
    },
    mounted() {
      setTimeout(() => {
        this.$refs.autoDownload.click();
      }, 2000);
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v4-container-redBag {
    height: 5.3rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-box {
      position: absolute;
      bottom: -0.5rem;
      .hand {
        width: 1rem;
        height: 1rem;
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;
        animation: handAni 1s linear infinite;
      }
    }
    &-btn {
      width: 4.46rem;
      height: 0.94rem;
    }
    .close {
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      top: 0.8rem;
      right: 1rem;
    }
  }
  @keyframes handAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
