<template>
  <div :class="['share-landing-v2', isRegBag ? 'red-bag' : '']">
    <Header :titleUrl="require('@/assets/images/share-landing-v1/invite-font.png')"></Header>
    <FriendGet v-if="isRegBag" :nameHeight="0.4"></FriendGet>
    <div class="share-landing-v2-container">
      <BallCont :changeRedBagShow="changeRedBagShow" :countDownTimer="countDownTimer" v-if="!isRegBag"></BallCont>
      <RedBagCont
        :tenCountDown="tenCountDown"
        :tenCountDownTimer="tenCountDownTimer"
        :changeRedBagShow="changeRedBagShow"
        :copyDownload="copyDownload"
        v-else
      ></RedBagCont>

      <div class="share-landing-v2-container-dis">
        <div class="share-landing-v2-container-dis-title">
          <img src="@/assets/images/share-landing-new/icon.png" alt="icon" />
          看看大家的评价
          <img src="@/assets/images/share-landing-new/icon.png" alt="icon" />
        </div>
        <div class="share-landing-v2-container-dis-cont">
          <DiscussContent color="#FEDFAF" :background="`#FE6333`"></DiscussContent>
        </div>
      </div>
    </div>
    <button
      id="v2-download-btn"
      @click="
        copyDownload(
          '#v2-download-btn',
          '点击下载快音，一起听歌赚钱',
          isRegBag ? '落地页常态优化B-1' : '落地页常态优化B'
        )
      "
      :data-clipboard-text="clipboardText"
      class="share-landing-v2-btn__download"
    >
      点击下载快音 一起听歌赚钱
    </button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import FriendGet from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/FriendGet';
  import DiscussContent from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/DiscussContent';
  import Header from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/Header';
  import BallCont from '@/views/Welfare/Fission/ShareLanding/ShareLandingV2/components/BallCont';
  import RedBagCont from '@/views/Welfare/Fission/ShareLanding/ShareLandingV2/components/RedBagCont';
  export default {
    name: 'ShareLandingV1',
    components: { RedBagCont, BallCont, FriendGet, DiscussContent, Header },
    props: {
      countDownTimer: {
        type: Object,
        default: () => {},
      },
      copyDownload: {
        type: Function,
        default: () => {},
      },
      isRegBag: {
        type: Boolean,
        default: false,
      },
      changeRedBagShow: {
        type: Function,
        default: () => {},
      },
      tenCountDown: {
        type: Function,
        default: () => {},
      },
      tenCountDownTimer: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters('share/shareLanding', [
        'clipboardText',
        'userInfo',
        'inviteLandingAb',
        'inviteCode',
        'clipboardText',
        'inviteLandingAb',
        'showMaxReward',
      ]),
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v2 {
    user-select: none;
    background: url('~@/assets/images/share-landing-v2/bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.red-bag {
      background: url('~@/assets/images/share-landing-v2/red-bg.png') no-repeat no-repeat;
      background-size: 100% 100%;
    }

    &-container {
      height: 15.39rem;
      width: 100%;
      margin-top: 0.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-dis {
        height: 10.83rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-title {
          display: flex;
          align-items: center;
          color: #ff5c18;
          font-size: 0.34rem;
          font-weight: bold;
          margin-top: 0.8rem;
          img {
            width: 0.66rem;
            height: 0.36rem;
            margin: 0 0.2rem;
          }
        }
        &-cont {
          width: 6.7rem;
          height: 4.5rem;
          background: linear-gradient(180deg, #fbe4bf 0%, #fcb377 64%, #ffbf89 100%);
          border-radius: 0.2rem;
          margin-top: 0.5rem;
        }
      }
    }
    &-btn__download {
      width: 6.44rem;
      height: 1.1rem;
      background: linear-gradient(180deg, #bbf4ff 0%, #63e0ff 13%, #2989ff 93%, #8bbbff 100%);
      box-shadow: 0 0.07rem 0.11rem 0 #3a2ff1;
      border-radius: 0.55rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.44rem;
      font-weight: bold;
      color: #fff;
      position: fixed;
      bottom: 0.6rem;
      border: none;
      animation: downloadBtnAni 1.5s linear infinite;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  @keyframes downloadBtnAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
