<template>
  <div :style="`top:${top}rem;left:${left}rem`" class="share-landing-v3-container-book">
    <img class="share-landing-v3-container-book-guang" src="@/assets/images/share-landing-v4/guang.png" alt="guang" />
    <img class="share-landing-v3-container-book-logo" src="@/assets/images/share-landing-v4/logo.png" alt="logo" />
    <img class="share-landing-v3-container-book-xing" src="@/assets/images/share-landing-v4/xing.png" alt="logo" />
  </div>
</template>

<script>
  export default {
    name: 'BookContent',
    props: {
      top: {
        type: Number,
        default: 1.6,
      },
      left: {
        type: Number,
        default: 1.6,
      },
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v3-container-book {
    position: absolute;
    //top: 1.6rem;
    //left: 1.6rem;
    background: url('~@/assets/images/share-landing-v4/guang-bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    width: 1.62rem;
    height: 1.62rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &-guang {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      animation: lightAni 5s linear infinite;
      z-index: 0;
    }
    &-xing {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 2;
      animation: startAni 3s linear infinite;
    }
    &-logo {
      width: 0.94rem;
      height: 0.94rem;
      z-index: 1;
    }
  }
  @keyframes lightAni {
    0% {
      transform: scale(1) rotate(0deg);
    }
    25% {
      transform: scale(0.86) rotate(90deg);
    }

    50% {
      transform: scale(1) rotate(180deg);
    }
    75% {
      transform: scale(0.86) rotate(270deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
  }
  @keyframes startAni {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.3;
    }

    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
</style>
