<template>
  <div class="primary-red-bag">
    <div
      :style="`background:${friendGetList.length > 0 ? 'rgba(255, 59, 59, 0.21)' : ''}`"
      class="primary-red-bag-name"
    >
      <div
        :class="['primary-red-bag-name-item', isNeedTransition ? 'item-transition' : '']"
        :style="`top:-${friendItemTopDistance}rem`"
        v-for="(item, k) in friendGetList"
        :key="k"
      >
        {{ item }}
      </div>
    </div>
    <div class="primary-red-bag-title">
      你有最高 <span class="primary-red-bag-title-active">{{ showMaxReward }}</span
      >元待领取
    </div>
    <div class="primary-red-bag-info">
      <img class="primary-red-bag-info-bag" src="@/assets/images/share-landing-new/small-bg.png" alt="小红包" />
      新人红包
    </div>

    <div class="primary-red-bag-code">
      <div>邀请码：{{ inviteCode }}</div>
      <div
        @click="copyInviteCode('#primary-btn-get', true)"
        :data-clipboard-text="clipboardText"
        id="primary-btn-get"
        class="primary-red-bag-code-btn__copy"
      >
        复制
      </div>
    </div>
    <div class="primary-red-bag-content">
      <div v-if="inviteLandingAb === 'c'" class="primary-red-bag-content-phone">
        <img
          class="primary-red-bag-content-phone-img__phone"
          src="@/assets/images/share-landing-new/phone.png"
          alt="手机"
          v-show="!userPhone"
        />
        <input
          :style="userPhone ? 'padding-left: 0.2rem' : ''"
          placeholder="输入手机号领取红包"
          @click="phoneInput"
          maxlength="11"
          class="primary-red-bag-content-phone-input"
          v-model="userPhone"
        />
      </div>
      <div
        id="new-share-landing-get-btn"
        :data-clipboard-text="clipboardText"
        @click="immediateGet"
        class="primary-red-bag-content-btn__get"
      >
        立即领取
      </div>
    </div>
    <div class="primary-red-bag-time-warp">
      <div class="primary-red-bag-time-warp-item">{{ countDownTimer.hour }}</div>
      :
      <div class="primary-red-bag-time-warp-item">{{ countDownTimer.minutes }}</div>
      :
      <div class="primary-red-bag-time-warp-item">{{ countDownTimer.seconds }}</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'PrimaryRedBag',
    props: {
      countDownTimer: {
        type: Object,
        default: () => {},
      },
      copyDownload: {
        type: Function,
        default: () => {},
      },
      copyInviteCode: {
        type: Function,
        default: () => {},
      },
      changeShareLandingModalShow: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        friendGetList: [],
        friendItemTopDistance: 0,
        isNeedTransition: true,
        reSetScrollFlag: 0,
        scrollTimer: 0,
        userPhone: '',
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', [
        'inviteCode',
        'clipboardText',
        'shufflingInfo',
        'inviteLandingAb',
        'showMaxReward',
      ]),
    },
    created() {},
    beforeMount() {
      this.reSetScrollFlag = this.shufflingInfo.length;
      if (this.reSetScrollFlag > 0) {
        this.friendGetList = [...this.shufflingInfo, this.shufflingInfo[0]];
        this.friendItemScroll();
      }
    },
    filters: {
      /**
       * @description 补零
       * @param { Number } num 传入需补零数字
       */
      addAero(num) {
        return num < 10 ? `0${num}` : num;
      },
    },
    mounted() {},
    methods: {
      phoneInput() {
        this.$track({
          page_title: `任务落地页${this.inviteLandingAb}`,
          element_name: '填写手机框',
        });
      },
      /**
       * @description 好友获得滚动
       */
      friendItemScroll() {
        setInterval(() => {
          if (this.reSetScrollFlag === this.scrollTimer) {
            // 暂停过渡效果
            this.isNeedTransition = false;
            // 恢复滚动区初始位置
            this.friendItemTopDistance = 0;
            this.scrollTimer = 0;
            // 开启过渡效果
            setTimeout(() => {
              this.isNeedTransition = true;
              this.friendItemTopDistance += 0.5;
              this.scrollTimer++;
            }, 100);
            return;
          }
          // 偏移值递增
          this.friendItemTopDistance += 0.5;
          this.scrollTimer++;
        }, 1500);
      },
      /**
       * @description 立即领取
       */

      immediateGet() {
        // ab为b和a 不需要填写手机号直接领取
        if (this.inviteLandingAb === 'b' || this.inviteLandingAb === 'a') {
          this.$track({
            page_title: `任务落地页${this.inviteLandingAb}`,
            element_name: '立即领取',
          });
          this.changeShareLandingModalShow(true);
          this.copyInviteCode('#new-share-landing-get-btn', false);
          return;
        }
        if (!this.userPhone) {
          this.$toast('您未填写手机号，领取失败');
        } else if (
          !/^\d+$/.test(this.userPhone) ||
          this.userPhone.length !== 11 ||
          this.userPhone.indexOf(' ') !== -1
        ) {
          this.$toast('请填写11位手机号');
        } else {
          this.$track({
            page_title: `任务落地页${this.inviteLandingAb}`,
            element_name: '填写完成button',
          });
          this.changeShareLandingModalShow(true);
          this.copyInviteCode('#new-share-landing-get-btn', false);
        }
      },
    },
  };
</script>

<style scoped lang="less">
  .primary-red-bag {
    background: url('~@/assets/images/share-landing-new/red-bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    height: 8.6rem;
    width: 100%;
    position: relative;
    top: -0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: 0.36rem;
      font-weight: 600;
      color: #b94f10;
      margin-top: 0.2rem;
      &-active {
        font-size: 0.8rem;
        color: #ff2828;
      }
    }
    &-name {
      width: 3.8rem;
      color: #ff2828;
      font-size: 0.24rem;
      font-weight: 600;

      border-radius: 0.25rem;
      margin-top: 1.3rem;
      overflow: hidden;
      height: 0.5rem;
      &-item {
        position: relative;
        text-align: center;
        height: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &-info {
          max-width: 2rem;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.item-transition {
          transition: top 1s;
        }
      }
    }
    &-info {
      font-size: 0.28rem;
      font-weight: 600;
      color: #ff4a4a;
      display: flex;
      align-items: center;
      margin-top: 0.3rem;
      &-bag {
        width: 0.24rem;
        height: 0.29rem;
        margin-right: 0.16rem;
      }
    }
    &-time-warp {
      display: flex;
      color: #fff;
      align-items: center;
      font-weight: bold;
      margin-top: 0.2rem;
      font-size: 0.24rem;
      &-item {
        width: 0.46rem;
        height: 0.46rem;
        margin: 0 0.1rem;
        background: linear-gradient(#ffd7b9, #ffb47c);
        font-size: 0.3rem;
        font-weight: bold;
        color: #b94f10;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.06rem;
      }
    }

    &-code {
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0.04rem;
      color: #ffffff;
      padding: 0.04rem 0.1rem;
      margin-top: 0.6rem;
      font-size: 0.24rem;
      &-btn__copy {
        width: 0.6rem;
        height: 0.28rem;
        border-radius: 0.14rem;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.18rem;
        margin-left: 0.16rem;
      }
    }
    &-content {
      &-btn__get {
        margin-top: 0.2rem;
        width: 4.56rem;
        height: 0.88rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.44rem;
        background: #d8d8d8 linear-gradient(180deg, #fff5e4 0%, #ffd29e 52%, #ffdea9 100%);
        box-shadow: 0 0.02rem 0.12rem 0 rgba(255, 29, 29, 0.5);
        font-size: 0.4rem;
        font-weight: 600;
        color: #b94f10;
      }
      &-phone {
        margin-top: 0.2rem;
        width: 4.56rem;
        height: 0.88rem;
        display: flex;
        align-items: center;
        border-radius: 0.44rem;
        background-color: #fff;
        padding: 0 0.2rem;
        box-sizing: border-box;
        font-size: 0.32rem;
        overflow: hidden;
        &-input {
          width: 3rem;
        }
        &-img__phone {
          width: 0.44rem;
          height: 0.44rem;
        }
      }
    }
  }
</style>
