<template>
  <div>
    <ShareLandingV0
      :countDownTimer="countDownTimer"
      :copyDownload="copyDownload"
      v-if="inviteLandingAbV2 === 'c'"
    ></ShareLandingV0>
    <!--    老虎机-->
    <ShareLandingV1
      :copyDownload="copyDownload"
      :isRegBag="isRegBag"
      :tenCountDown="tenCountDown"
      :tenCountDownTimer="tenCountDownTimer"
      :changeRedBagShow="changeRedBagShow"
      v-if="inviteLandingAbV2 === 'a'"
      :countDownTimer="countDownTimer"
    ></ShareLandingV1>
    <!--    气球-->
    <ShareLandingV2
      :copyDownload="copyDownload"
      :tenCountDown="tenCountDown"
      :tenCountDownTimer="tenCountDownTimer"
      :isRegBag="isRegBag"
      :changeRedBagShow="changeRedBagShow"
      v-if="inviteLandingAbV2 === 'b'"
      :countDownTimer="countDownTimer"
    ></ShareLandingV2>
    <!--    飞行降落-->
    <ShareLandingV3
      :copyDownload="copyDownload"
      v-if="inviteLandingAbV2 === 'd' || inviteLandingAbV2 === 'e' || inviteLandingAbV2 === 'g'"
    ></ShareLandingV3>
    <!--   搞笑类-->
    <ShareLandingV4 :copyDownload="copyDownload" v-if="inviteLandingAbV2 === 'f'"></ShareLandingV4>
    <ShareLandingV5 :copyDownload="copyDownload" v-if="inviteLandingAbV2 === 'h'"></ShareLandingV5>

    <!--    用于点击空白处，复制邀请码-->
    <div ref="copyCode" id="copy-code" @click="copyCode" :data-clipboard-text="umLink.clipboardInfo"></div>
  </div>
</template>

<script>
  import ShareLandingV1 from '@/views/Welfare/Fission/ShareLanding/ShareLandingV1/index';
  import ShareLandingV2 from '@/views/Welfare/Fission/ShareLanding/ShareLandingV2/index';
  import ShareLandingV0 from '@/views/Welfare/Fission/ShareLanding/ShareLandingV0/index';
  import ShareLandingV3 from '@/views/Welfare/Fission/ShareLanding/ShareLandingV3/index';
  import ShareLandingV4 from '@/views/Welfare/Fission/ShareLanding/ShareLandingV4/index';
  import ShareLandingV5 from '@/views/Welfare/Fission/ShareLanding/ShareLandingV5/index';

  import { mapGetters } from 'vuex';
  import Clipboard from 'clipboard';
  export default {
    name: 'ShareLandingNew',
    components: { ShareLandingV0, ShareLandingV1, ShareLandingV2, ShareLandingV3, ShareLandingV4, ShareLandingV5 },
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
      umLink: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        countDownTime: 86400, // 24小时
        countDownTimer: {
          hour: 24,
          minutes: '00',
          seconds: '00',
        },
        tenCountDownTime: 600, // 10分钟
        tenCountDownTimer: {
          seconds: '00',
          minutes: 10,
        },
        isRegBag: false,
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', ['inviteLandingAbV2', 'clipboardText', 'pageTitle']),
    },
    mounted() {
      // 点击空白处加上埋点以及复制邀请码到剪切板

      window.addEventListener('click', this.clickCallBack);
      this.$on('hook:beforeDestroy', () => {
        window.removeEventListener('click', this.clickCallBack);
      });
    },

    beforeMount() {
      // 滚动到列表长度
      this.countDown();
    },

    methods: {
      /**
       * @description 点击空白处复制邀请码
       */
      copyCode() {
        new Clipboard('#copy-code');
      },

      /**
       * @description 点击空白回调
       */

      clickCallBack(e) {
        if (!e.target.id) {
          let page_title = this.pageTitle;
          if (this.inviteLandingAbV2 === 'b') {
            page_title = this.isRegBag ? '落地页常态优化B-1' : '落地页常态优化B';
          }
          this.$track({
            page_title,
            element_name: '空白点击',
          });
          this.$refs.copyCode.click();
        }
      },
      /**
       * @description 24小时倒计时
       */

      countDown() {
        let interval = setInterval(() => {
          this.countDownTime--;
          if (this.countDownTime === 0) {
            clearInterval(interval);
            this.countDownTimer.seconds = '00';
            this.countDownTimer.minutes = '00';
            this.countDownTimer.hour = '00';
            return;
          }
          let countDownHours = Math.floor((this.countDownTime / 60 / 60) % 24); // 获取小时
          let countDownMinutes = Math.floor((this.countDownTime / 60) % 60);
          let countDownSeconds = Math.floor(this.countDownTime % 60);
          // 判断天、时、分、秒是不是两位数，如果是直接输出，如果不是在前边加个0;
          countDownHours < 10 ? (countDownHours = '0' + countDownHours) : countDownHours;
          countDownMinutes < 10 ? (countDownMinutes = '0' + countDownMinutes) : countDownMinutes;
          countDownSeconds < 10 ? (countDownSeconds = '0' + countDownSeconds) : countDownSeconds;
          this.countDownTimer.seconds = countDownSeconds;
          this.countDownTimer.minutes = countDownMinutes;
          this.countDownTimer.hour = countDownHours;
        }, 1000);
        this.$once('hook:beforeDestroy', () => {
          clearInterval(interval);
        });
      },
      /**
       * @description 10分钟倒计时
       */

      tenCountDown() {
        let interval = setInterval(() => {
          this.tenCountDownTime--;
          if (this.tenCountDownTime === 0) {
            clearInterval(interval);
            this.tenCountDownTimer.seconds = '00';
            this.tenCountDownTimer.minutes = '00';
            this.changeRedBagShow(false);
            return;
          }
          let countDownMinutes = Math.floor(this.tenCountDownTime / 60);
          let countDownSeconds = Math.floor(this.tenCountDownTime % 60);
          // 判断天、时、分、秒是不是两位数，如果是直接输出，如果不是在前边加个0;
          countDownMinutes < 10 ? (countDownMinutes = '0' + countDownMinutes) : countDownMinutes;
          countDownSeconds < 10 ? (countDownSeconds = '0' + countDownSeconds) : countDownSeconds;
          this.tenCountDownTimer.seconds = countDownSeconds;
          this.tenCountDownTimer.minutes = countDownMinutes;
        }, 1000);
        this.$once('hook:beforeDestroy', () => {
          clearInterval(interval);
        });
      },
      /**
       * @description 改变红包显示
       * @param { Boolean } flag
       * @param {String} page_title
       * @param {String} element_name
       */

      changeRedBagShow(flag, page_title, element_name) {
        page_title &&
          this.$track({
            page_title,
            element_name,
          });
        this.isRegBag = flag;
      },
    },
  };
</script>

<style scoped lang="less">
  .share-landing-new {
    user-select: none;
    background: url('~@/assets/images/share-landing-new/bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-container {
      height: 7rem;
      width: 100%;
    }
    &-discuss {
      background: url('~@/assets/images/share-landing-new/discuss-bg.png') no-repeat no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 6.5rem;
      display: flex;
      justify-content: center;
      &-content {
        margin-top: 1rem;
        background-color: #fff;
        height: 5rem;
        width: 6.9rem;
        border-radius: 0.18rem;
        display: flex;
        flex-direction: column;
        &-title {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #aa5509;
          font-size: 0.34rem;
          font-weight: 600;
          height: 1rem;
          width: 100%;
          &-img__icon {
            width: 0.66rem;
            height: 0.36rem;
            margin: 0 0.2rem;
          }
        }
        &-warp {
          height: 4rem;
          overflow: hidden;
          padding: 0 0.4rem;
          &-item {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0.3rem 0;
            &.item-transition {
              transition: top 1s;
            }
            &-info {
              padding: 0 0.2rem;
              color: #666666;
              font-size: 0.28rem;
              font-weight: 600;
              border-radius: 0.1rem;
              margin-left: 0.5rem;
              height: 0.8rem;
              display: flex;
              align-items: center;
              position: relative;
              &-cont {
                display: inline-block;
                max-width: 4.3rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              &.ffe0c9 {
                background-color: #ffe0c9;
              }
              &.ffdfe2 {
                background-color: #ffdfe2;
              }
              &-icon {
                display: block;
                position: absolute;
                left: -0.1rem;
                top: 0.4rem;
                transform: translateY(-0.05rem);
                width: 0;
                height: 0;
                border-top: 0.1rem solid transparent;

                border-bottom: 0.1rem solid transparent;
                &.ffe0c9 {
                  border-right: 0.1rem solid #ffe0c9;
                }
                &.ffdfe2 {
                  border-right: 0.1rem solid #ffdfe2;
                }
              }
            }
            &-img__author {
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
            }
          }
        }
      }
    }
    &-btn {
      width: 6.44rem;
      height: 1.1rem;
      border-radius: 0.55rem;
      background: linear-gradient(180deg, #ffc9bb 0%, #ff8463 13%, #fd124d 93%, #ff5c85 100%);
      box-shadow: 0 0.07rem 0 0 #db4141;
      font-size: 0.44rem;
      color: #fff;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0;
      animation: downloadAni 1.5s infinite linear;
      position: fixed;
      bottom: 0;
    }
    &-btn-warp {
      background-color: #ffdfe2;
      height: 2rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  @keyframes downloadAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
