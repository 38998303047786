<template>
  <div class="red-bag">
    <div class="red-bag-maxH">最高可得</div>
    <div class="red-bag-num">999元红包</div>
    <div class="red-bag-info">
      <img src="@/assets/images/share-landing-new/small-bg.png" alt="bg" />
      新人红包
    </div>
    <img class="red-bag-money" src="@/assets/images/share-landing-v1/money-bg.png" alt="bg" />
    <div class="red-bag-get">
      <img
        @click="copyDownload('#v2-get-btn', '立即领取', '落地页常态优化B-1')"
        :data-clipboard-text="clipboardText"
        id="v2-get-btn"
        class="red-bag-get-btn"
        src="@/assets/images/share-landing-v1/get-btn.png"
        alt="btn"
      />
      <img class="red-bag-get-pointer" src="@/assets/images/share-landing-v1/pointer.png" alt="btn" />
    </div>
    <div class="red-bag-reduce">{{ tenCountDownTimer.minutes }}:{{ tenCountDownTimer.seconds }}失效</div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'RedBagCont',
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText']),
    },
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
      changeRedBagShow: {
        type: Function,
        default: () => {},
      },
      tenCountDown: {
        type: Function,
        default: () => {},
      },
      tenCountDownTimer: {
        type: Object,
        default: () => {},
      },
    },
    beforeMount() {
      this.tenCountDown();
    },
  };
</script>

<style scoped lang="less">
  .red-bag {
    margin: 0.2rem 0 0.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    &-maxH {
      width: 1.4rem;
      height: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(250, 87, 42, 0.2);
      border-radius: 0.2rem;
      color: #fa572a;
      font-size: 0.24rem;
      margin-top: 0.2rem;
    }
    &-num {
      font-size: 0.52rem;
      font-weight: bold;
      color: #ff3700;
      margin-top: 0.1rem;
    }
    &-info {
      img {
        width: 0.24rem;
        height: 0.29rem;
        margin-right: 0.2rem;
      }
      margin-top: 0.1rem;
      display: flex;
      align-items: center;
      color: #ff4a4a;
      font-weight: bold;
      font-size: 0.28rem;
    }
    &-money {
      width: 2.72rem;
      height: 2.1rem;
      margin-top: 0.1rem;
    }
    &-get {
      margin-top: 0.1rem;
      position: relative;
      &-btn {
        width: 4.56rem;
        height: 1.22rem;
        animation: getBtnAni 1s linear infinite;
      }
      &-pointer {
        position: absolute;
        bottom: -0.5rem;
        right: 0.3rem;
        width: 0.95rem;
        height: 1.04rem;
        animation: pointerAni linear 1s infinite;
      }
    }
    &-reduce {
      width: 2.04rem;
      height: 0.44rem;
      border-radius: 0.22rem;
      background: rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.24rem;
      color: #fff;
      font-weight: bold;
      margin-top: 0.2rem;
    }
  }
  @keyframes pointerAni {
    0% {
      transform: scale(1);
      bottom: -0.5rem;
      right: 0.3rem;
    }
    50% {
      transform: scale(0.9);
      bottom: -0.7rem;
      right: 0.2rem;
    }
    100% {
      transform: scale(1);
      bottom: -0.5rem;
      right: 0.3rem;
    }
  }
  @keyframes getBtnAni {
    0% {
      transform: scale(1);
      bottom: -0.5rem;
      right: 0.3rem;
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
