<template>
  <div class="wallet">
    <div class="wallet-container">
      <div class="top">
        <p class="tips">仅限新用户领取</p>
        <h3 class="title">Hi~送你一个快音</h3>
        <p class="desc">限时超值惊喜，敬请您领取</p>
      </div>

      <div class="button vivify pulsate infinite">
        <button
          id="rp-btn"
          class="button-content"
          @click="copyDownload('#rp-btn', '立即领取')"
          :data-clipboard-text="clipboardText"
        >
          <div class="clock-area">
            <div class="clock">{{ countdown.h }}</div>
            <span class="white">:</span>
            <div class="clock">{{ countdown.m }}</div>
            <span class="white">:</span>
            <div class="clock">{{ countdown.s }}</div>
            <span class="white font14">后失效</span>
            <span class="receive">立即领取</span>
          </div>
        </button>
      </div>

      <div class="bottom">
        邀请码：{{ inviteCode }}
        <button id="copy" class="copy" @click="copyInviteCode" :data-clipboard-text="clipboardText">复制</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { twoByte } from '@/utils/common';
  import Clipboard from 'clipboard';

  export default {
    name: 'RedEnvelope',
    components: {},
    props: {
      copyDownload: {
        type: Function,
      },
    },
    data() {
      return {
        countdownTimer: null,
        countdown: {
          d: 0,
          h: 0,
          m: 0,
          s: 0,
        },
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', ['userInfo', 'inviteCode', 'clipboardText']),
    },
    watch: {},
    created() {},
    mounted() {
      this.startCountdown(86400);
    },
    methods: {
      /**
       * @description: 倒计时
       * @param {number} seconds - 倒计时
       */

      startCountdown(seconds) {
        clearInterval(this.countdownTimer);

        const countDown = () => {
          seconds--;
          if (seconds <= 0) {
            clearInterval(this.countdownTimer);
          }
          this.countdown.h = twoByte(String(Math.floor(seconds / 3600) % 24));
          this.countdown.m = twoByte(String(Math.floor((seconds % 3600) / 60)));
          this.countdown.s = twoByte(String(seconds % 60));
        };

        this.countdownTimer = setInterval(countDown, 1000);
        countDown();
      },

      /**
       * @description: 复制邀请码
       */

      copyInviteCode() {
        this.$track({
          page_title: `裂变落地页`,
          element_name: '复制邀请码',
          remarks: `${this.userInfo.uid};${this.inviteCode}`,
        });
        const clipboard = new Clipboard('#copy');

        clipboard.on('success', (e) => {
          e.clearSelection();
          this.$toast('复制成功，快去听歌赚钱吧！');
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .wallet {
    padding: 0 0.52rem;
  }
  .wallet-container {
    overflow: hidden;
    height: 4.96rem;
    background: url('https://static1.kaixinyf.cn/img/lza5fe1b02b649c4576639525.png') no-repeat center center;
    background-size: cover;
  }

  .wallet-container .top {
    position: relative;
    margin: 0.22rem auto 0;
    width: 4.96rem;
    height: 2.1rem;
    background: url('https://static1.kaixinyf.cn/img/lza5fe1b03766ea1109827971.png') no-repeat center center;
    background-size: cover;
  }
  .top .tips {
    position: absolute;
    top: 0.21rem;
    left: 1.74rem;
    font-size: 0.2rem;
    color: #4b1a87;
  }
  .top .title {
    position: absolute;
    top: 0.71rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.42rem;
    color: #ff0000;
  }
  .top .desc {
    position: absolute;
    top: 1.4rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0.24rem;
    color: #ff0000;
    font-weight: 500;
  }

  .button {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-top: 0.24rem;
    margin-left: 0.2rem;
    width: 5.94rem;
    height: 1.44rem;
    background: url('https://static1.kaixinyf.cn/img/lza5fe1b031bc2fe012631380.png') no-repeat center center;
    background-size: cover;
    border: 0;
  }
  .button-content {
    margin-top: 0.12rem;
    margin-left: 0.21rem;
    width: 5.54rem;
    height: 0.92rem;
    line-height: 0.92rem;
  }
  .clock-area {
    display: flex;
    align-items: center;
    margin-left: 0.46rem;
    width: 2.6rem;
    width: 2.6rem;
    height: 100%;
  }
  .clock {
    display: inline-block;
    width: 0.38rem;
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 0.24rem;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(180deg, #ff7219 0%, #fb3534 100%);
    border-radius: 0.08rem;
    padding: 0 0.04rem;
  }

  .bottom {
    height: 0.98rem;
    line-height: 0.98rem;
    font-size: 0.32rem;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
  }
  .copy {
    margin-left: 0.2rem;
    display: inline-block;
    padding: 2px 0.28rem;
    height: 0.36rem;
    line-height: 100%;
    font-size: 0.2rem;
    font-weight: 600;
    color: #ffffff;
    border-radius: 0.5rem;
    border: 1px solid #ffffff;
    background: none;
  }

  .white {
    color: white;
    margin: 0 0.08rem;
  }
  .font14 {
    font-size: 0.28rem;
    white-space: nowrap;
  }
  .receive {
    margin-left: 0.28rem;
    color: white;
    font-size: 0.4rem;
    font-weight: bold;
    white-space: nowrap;
  }
  button {
    border: none;
    background: none !important;
    padding: 0;
    margin: 0;
    vertical-align: baseline;
  }
</style>
