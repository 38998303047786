// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/share-landing-v3/bg-bot.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".share-landing-v3-container-cont[data-v-029bcb26]{height:8.8rem;width:100%;display:flex;flex-direction:column;align-items:center;margin-top:.3rem;z-index:1}.share-landing-v3-container-cont.abToF[data-v-029bcb26]{height:9.94rem}.share-landing-v3-container-cont.active[data-v-029bcb26]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.share-landing-v3-container-cont-title[data-v-029bcb26]{display:flex;align-items:center;color:#ff5c18;font-size:.34rem;font-weight:700;margin-top:1.2rem}.share-landing-v3-container-cont-title.abToF[data-v-029bcb26]{margin-top:.3rem}.share-landing-v3-container-cont-title img[data-v-029bcb26]{width:.66rem;height:.36rem;margin:0 .2rem}.share-landing-v3-container-cont-cont[data-v-029bcb26]{width:6.7rem;height:4.5rem;background:#ffe7c8;border-radius:.2rem;margin-top:.5rem}", ""]);
// Exports
module.exports = exports;
