<template>
  <div class="header">
    <div class="header-name">你的好友{{ userInfo && userInfo.role === 'normal' ? `${userInfo.nickname}` : '' }}</div>
    <img
      :style="{ width: `${width}rem`, height: `${height}rem` }"
      class="header-title"
      :src="titleUrl"
      alt="欢迎来快音"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'Header',
    props: {
      titleUrl: {
        type: String,
        default: '@/assets/images/share-landing-v1/invite-font.png',
      },
      width: {
        type: Number,
        default: 5.3,
      },
      height: {
        type: Number,
        default: 1.3,
      },
    },
    computed: {
      ...mapGetters('share/shareLanding', ['userInfo']),
    },
  };
</script>

<style scoped lang="less">
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-name {
      color: #fff;
      font-size: 0.36rem;
      font-weight: bold;
      line-height: 1rem;
      height: 1rem;
      text-align: center;
      width: 4.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-title {
      height: 1.3rem;
      width: 5.3rem;
    }
  }
</style>
