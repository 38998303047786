<template>
  <div class="share-landing-v3-container-redBag">
    <div class="redBag-cont">
      <div class="red-bag">
        <img
          @click="copyDownload('#v3-close-btn', '关闭按钮', inviteLandingAbV2 === 'd' ? '飞行降落A' : '飞行降落B')"
          :data-clipboard-text="clipboardText"
          class="close"
          src="@/assets/images/share-landing-v3/close.png"
          id="v3-close-btn"
          alt="close"
        />
        <div class="red-bag-maxH">最高可得</div>
        <div class="red-bag-num">999元红包</div>
        <div class="red-bag-info">
          <img src="@/assets/images/share-landing-new/small-bg.png" alt="bg" />
          新人红包
        </div>
        <img class="red-bag-money" src="@/assets/images/share-landing-v1/money-bg.png" alt="bg" />
        <div class="red-bag-get">
          <img
            @click="copyDownload('#v3-get-btn', '已存入账户', inviteLandingAbV2 === 'd' ? '飞行降落A' : '飞行降落B')"
            :data-clipboard-text="clipboardText"
            id="v3-get-btn"
            class="red-bag-get-btn"
            src="@/assets/images/share-landing-v3/btn.png"
            alt="btn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'RedBag',
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText', 'inviteLandingAbV2']),
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v3-container-redBag {
    height: 5.3rem;

    position: relative;
    .redBag-cont {
      background: url('~@/assets/images/share-landing-v1/red-bag-bg.png') no-repeat no-repeat;
      background-size: 100% 100%;
      width: 7rem;
      height: 6.9rem;
    }
  }
  .red-bag {
    margin: 0.2rem 0 0.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    .close {
      width: 0.48rem;
      height: 0.48rem;
      position: absolute;
      right: 0.92rem;
      top: 0.4rem;
    }
    &-maxH {
      width: 1.4rem;
      height: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fa572a;
      font-size: 0.24rem;
      margin-top: 0.5rem;
    }
    &-num {
      font-size: 0.52rem;
      font-weight: bold;
      color: #ff3700;
      margin-top: 0.1rem;
    }
    &-info {
      img {
        width: 0.24rem;
        height: 0.29rem;
        margin-right: 0.2rem;
      }
      margin-top: 0.1rem;
      display: flex;
      align-items: center;
      color: #ff4a4a;
      font-weight: bold;
      font-size: 0.28rem;
    }
    &-money {
      width: 2.72rem;
      height: 2.1rem;
      margin-top: 0.1rem;
    }
    &-get {
      margin-top: 0.1rem;
      position: relative;
      &-btn {
        width: 4.56rem;
        height: 1.22rem;
        animation: getBtnAni 1s linear infinite;
      }
    }
    &-reduce {
      width: 2.04rem;
      height: 0.44rem;
      border-radius: 0.22rem;
      background: rgba(255, 255, 255, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.24rem;
      color: #fff;
      font-weight: bold;
      margin-top: 0.2rem;
    }
  }

  @keyframes getBtnAni {
    0% {
      transform: scale(1);
      bottom: -0.5rem;
      right: 0.3rem;
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
