<template>
  <div class="share-landing-new">
    <Header :titleUrl="require('@/assets/images/share-landing-new/title-font.png')"></Header>
    <div class="share-landing-new-container">
      <NeedOpenRedBag
        :countDownTimer="countDownTimer"
        :openRedBag="openRedBag"
        :copyInviteCode="copyInviteCode"
        v-if="isNeedOpenRedBag"
      ></NeedOpenRedBag>
      <transition name="fade">
        <PrimaryRedBag
          :copyDownload="copyDownload"
          :copyInviteCode="copyInviteCode"
          :countDownTimer="countDownTimer"
          v-if="!isNeedOpenRedBag"
          :changeShareLandingModalShow="changeShareLandingModalShow"
        ></PrimaryRedBag>
      </transition>
    </div>
    <div class="share-landing-new-discuss">
      <div class="share-landing-new-discuss-content">
        <div class="share-landing-new-discuss-content-title">
          <img
            class="share-landing-new-discuss-content-title-img__icon"
            src="@/assets/images/share-landing-new/icon.png"
            alt="icon"
          />
          看看大家的评论
          <img
            class="share-landing-new-discuss-content-title-img__icon"
            src="@/assets/images/share-landing-new/icon.png"
            alt="icon"
          />
        </div>
        <div class="share-landing-new-discuss-content-warp" ref="scroll">
          <div
            v-for="(item, k) in discussList"
            :key="k"
            :class="['share-landing-new-discuss-content-warp-item', isNeedTransition ? 'item-transition' : '']"
            :style="`top: -${discussItemTopDistance}rem`"
          >
            <img class="share-landing-new-discuss-content-warp-item-img__author" :src="item.avatar_small" alt="头像" />
            <div :class="['share-landing-new-discuss-content-warp-item-info', (k + 1) % 2 === 0 ? 'ffe0c9' : 'ffdfe2']">
              <label
                :class="[
                  'share-landing-new-discuss-content-warp-item-info-icon',
                  (k + 1) % 2 === 0 ? 'ffe0c9' : 'ffdfe2',
                ]"
              ></label>
              <span class="share-landing-new-discuss-content-warp-item-info-cont">{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="share-landing-new-btn-warp">
      <div
        id="new-download-btn"
        @click="copyDownload('#new-download-btn', '固定button')"
        class="share-landing-new-btn"
        :data-clipboard-text="clipboardText"
      >
        点击下载快音 一起听歌赚钱
      </div>
    </div>

    <ShareLandingModal
      :changeShareLandingModalShow="changeShareLandingModalShow"
      v-if="isShareLandingModalShow"
      :copyDownload="copyDownload"
    ></ShareLandingModal>
  </div>
</template>

<script>
  import NeedOpenRedBag from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/NeedOpenRedBag';
  import PrimaryRedBag from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/PrimaryRedBag';
  import ShareLandingModal from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/ShareLandingModal';
  import Header from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/Header';
  import { mapGetters } from 'vuex';
  import Clipboard from 'clipboard';
  export default {
    name: 'ShareLandingNew',
    components: { NeedOpenRedBag, PrimaryRedBag, ShareLandingModal, Header },
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
      countDownTimer: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        isNeedOpenRedBag: true,
        discussList: [],
        interval: null,
        scrollInterval: null,
        discussItemTopDistance: 0, //评论内容偏移值
        isNeedTransition: true, //是否需要过渡动画
        reSetScrollFlag: 0,
        scrollTimer: 0,
        isShareLandingModalShow: false,
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText', 'userInfo', 'inviteLandingAb', 'evaluation']),
    },
    created() {
      // 判断展示ab
      if (this.inviteLandingAb === 'a') {
        this.isNeedOpenRedBag = true;
      } else if (this.inviteLandingAb === 'b' || this.inviteLandingAb === 'c') {
        this.isNeedOpenRedBag = false;
      }
    },
    beforeMount() {
      // 滚动到列表长度
      this.reSetScrollFlag = this.evaluation.length;
      this.discussList = [...this.evaluation, ...this.evaluation];
      this.discussScrollInterval();
    },
    methods: {
      /**
       * @description 开启红包
       */

      openRedBag() {
        this.$track({
          page_title: `任务落地页a`,
          element_name: '开',
        });
        this.isNeedOpenRedBag = false;
      },
      /**
       * @description 评论区滚动效果
       */

      discussScrollInterval() {
        this.scrollInterval = setInterval(() => {
          if (this.scrollTimer === this.reSetScrollFlag) {
            // 暂停过渡效果
            this.isNeedTransition = false;
            // 恢复评论区初始位置
            this.discussItemTopDistance = 0;
            this.scrollTimer = 0;
            // 开启过渡效果
            setTimeout(() => {
              this.isNeedTransition = true;
              this.discussItemTopDistance += 1.1;
              this.scrollTimer++;
            }, 100);
            return;
          }
          // 偏移值递增
          this.discussItemTopDistance += 1.1;
          this.scrollTimer++;
        }, 1500);
        this.$once('hook:beforeDestroy', () => {
          clearInterval(this.scrollInterval);
        });
      },
      /**
       * @description 复制邀请码
       * @param {String} element 节点ID
       * @param {Boolean} isNeedToast 是否需要toast
       */

      copyInviteCode(element, isNeedToast) {
        try {
          const clipboard = new Clipboard(element);

          clipboard.on('success', (e) => {
            e.clearSelection();
            if (isNeedToast) this.$toast('复制成功');
          });
          clipboard.on('error', () => {
            if (isNeedToast) this.$toast('复制失败');
          });
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      /**
       * @description 改变弹窗状态
       * @param { Boolean } flag
       */
      changeShareLandingModalShow(flag) {
        this.isShareLandingModalShow = flag;
      },
    },
  };
</script>

<style scoped lang="less">
  .share-landing-new {
    user-select: none;
    background: url('~@/assets/images/share-landing-new/bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-container {
      height: 7rem;
      width: 100%;
    }
    &-discuss {
      background: url('~@/assets/images/share-landing-new/discuss-bg.png') no-repeat no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 6.5rem;
      display: flex;
      justify-content: center;
      &-content {
        margin-top: 1rem;
        background-color: #fff;
        height: 5rem;
        width: 6.9rem;
        border-radius: 0.18rem;
        display: flex;
        flex-direction: column;
        &-title {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #aa5509;
          font-size: 0.34rem;
          font-weight: 600;
          height: 1rem;
          width: 100%;
          &-img__icon {
            width: 0.66rem;
            height: 0.36rem;
            margin: 0 0.2rem;
          }
        }
        &-warp {
          height: 4rem;
          overflow: hidden;
          padding: 0 0.4rem;
          &-item {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0.3rem 0;
            &.item-transition {
              transition: top 1s;
            }
            &-info {
              padding: 0 0.2rem;
              color: #666666;
              font-size: 0.28rem;
              font-weight: 600;
              border-radius: 0.1rem;
              margin-left: 0.5rem;
              height: 0.8rem;
              display: flex;
              align-items: center;
              position: relative;
              &-cont {
                display: inline-block;
                max-width: 4.3rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              &.ffe0c9 {
                background-color: #ffe0c9;
              }
              &.ffdfe2 {
                background-color: #ffdfe2;
              }
              &-icon {
                display: block;
                position: absolute;
                left: -0.1rem;
                top: 0.4rem;
                transform: translateY(-0.05rem);
                width: 0;
                height: 0;
                border-top: 0.1rem solid transparent;

                border-bottom: 0.1rem solid transparent;
                &.ffe0c9 {
                  border-right: 0.1rem solid #ffe0c9;
                }
                &.ffdfe2 {
                  border-right: 0.1rem solid #ffdfe2;
                }
              }
            }
            &-img__author {
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
            }
          }
        }
      }
    }
    &-btn {
      width: 6.44rem;
      height: 1.1rem;
      border-radius: 0.55rem;
      background: linear-gradient(180deg, #ffc9bb 0%, #ff8463 13%, #fd124d 93%, #ff5c85 100%);
      box-shadow: 0 0.07rem 0 0 #db4141;
      font-size: 0.44rem;
      color: #fff;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0;
      animation: downloadAni 1.5s infinite linear;
      position: fixed;
      bottom: 0;
    }
    &-btn-warp {
      background-color: #ffdfe2;
      height: 2rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  @keyframes downloadAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
