// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/share-landing-v4/guang-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".share-landing-v3-container-book[data-v-39ed5b8e]{position:absolute;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;width:1.62rem;height:1.62rem;display:flex;justify-content:center;align-items:center}.share-landing-v3-container-book-guang[data-v-39ed5b8e]{position:absolute;top:0;right:0;left:0;bottom:0;-webkit-animation:lightAni-data-v-39ed5b8e 5s linear infinite;animation:lightAni-data-v-39ed5b8e 5s linear infinite;z-index:0}.share-landing-v3-container-book-xing[data-v-39ed5b8e]{position:absolute;top:0;right:0;left:0;bottom:0;z-index:2;-webkit-animation:startAni-data-v-39ed5b8e 3s linear infinite;animation:startAni-data-v-39ed5b8e 3s linear infinite}.share-landing-v3-container-book-logo[data-v-39ed5b8e]{width:.94rem;height:.94rem;z-index:1}@-webkit-keyframes lightAni-data-v-39ed5b8e{0%{transform:scale(1) rotate(0deg)}25%{transform:scale(.86) rotate(90deg)}50%{transform:scale(1) rotate(180deg)}75%{transform:scale(.86) rotate(270deg)}to{transform:scale(1) rotate(1turn)}}@keyframes lightAni-data-v-39ed5b8e{0%{transform:scale(1) rotate(0deg)}25%{transform:scale(.86) rotate(90deg)}50%{transform:scale(1) rotate(180deg)}75%{transform:scale(.86) rotate(270deg)}to{transform:scale(1) rotate(1turn)}}@-webkit-keyframes startAni-data-v-39ed5b8e{0%{opacity:1}25%{opacity:.3}50%{opacity:1}75%{opacity:.3}to{opacity:1}}@keyframes startAni-data-v-39ed5b8e{0%{opacity:1}25%{opacity:.3}50%{opacity:1}75%{opacity:.3}to{opacity:1}}", ""]);
// Exports
module.exports = exports;
