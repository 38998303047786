<template>
  <div class="share-landing-v2-container-yellow-bag">
    <FriendGet :nameHeight="0.4" :font-size="0.2" :width="3.2" color="#ff5c18"></FriendGet>
    <div class="share-landing-v2-container-yellow-bag-info">戳个喜欢的</div>
    <div class="share-landing-v2-container-yellow-bag-ball-cont">
      <div class="share-landing-v2-container-yellow-bag-ball-cont-item1">
        <img class="ball" src="@/assets/images/share-landing-v2/ball-blue.png" alt="气球" />
        <img v-if="selectBall === 1" class="hand" src="@/assets/images/share-landing-v2/hand@2x.png" alt="hand" />
        <div id="ball-one" @click="luckyDraw(1, '气球左')" class="clickDom"></div>
      </div>
      <div class="share-landing-v2-container-yellow-bag-ball-cont-item2">
        <img class="ball" src="@/assets/images/share-landing-v2/ball-red.png" alt="气球" />
        <img v-if="selectBall === 2" class="hand" src="@/assets/images/share-landing-v2/hand@2x.png" alt="hand" />
        <div id="ball-two" @click="luckyDraw(2, '气球中')" class="clickDom"></div>
      </div>
      <div class="share-landing-v2-container-yellow-bag-ball-cont-item3">
        <img class="ball" src="@/assets/images/share-landing-v2/ball-pink.png" alt="气球" />
        <img v-if="selectBall === 3" class="hand" src="@/assets/images/share-landing-v2/hand@2x.png" alt="hand" />
        <div id="ball-three" @click="luckyDraw(3, '气球右')" class="clickDom"></div>
      </div>
    </div>
    <div class="share-landing-v2-container-yellow-bag-count">
      {{ countDownTimer.hour }}:{{ countDownTimer.minutes }}:{{ countDownTimer.seconds }} 即将消失
    </div>
  </div>
</template>

<script>
  import FriendGet from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/FriendGet';
  export default {
    name: 'BallCont',
    components: { FriendGet },
    props: {
      countDownTimer: {
        type: Object,
        default: () => {},
      },
      changeRedBagShow: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        selectBall: 2,
      };
    },
    methods: {
      /**
       * @description 戳气球
       * @param { Number } flag
       * @param {String} element_name
       */

      luckyDraw(flag, element_name) {
        this.selectBall = flag;
        setTimeout(() => {
          this.changeRedBagShow(true, '落地页常态优化B', element_name);
        }, 500);
      },
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v2-container-yellow-bag {
    //background: url('~@/assets/images/share-landing-v2/yellow-bag.png') no-repeat no-repeat;
    background-size: 100% 100%;
    width: 6.46rem;
    height: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.4rem 0;
    &-info {
      font-size: 0.5rem;
      font-weight: bolder;
      color: #ff5c18;
      margin-top: 0.14rem;
    }
    &-ball-cont {
      display: flex;
      &-item1 {
        width: 1.68rem;
        height: 3.28rem;
        position: relative;
        .ball {
          width: 100%;
          height: 100%;
          animation: ballOneAni 6s linear infinite;
        }
        .hand {
          width: 0.82rem;
          height: 0.94rem;
          position: absolute;
          right: 0;
          top: 1.2rem;
          animation: handAni 1s linear infinite;
        }
        .clickDom {
          width: 105%;
          height: 2rem;
          position: absolute;
          top: 0;
        }
      }
      &-item2 {
        width: 2.3rem;
        height: 3.96rem;
        position: relative;
        .clickDom {
          width: 105%;
          height: 2rem;
          position: absolute;
          top: 0.5rem;
        }
        .ball {
          width: 100%;
          height: 100%;
          transform-origin: 0 50%;
          animation: ballTwoAni 9s linear infinite;
        }
        .hand {
          width: 0.82rem;
          height: 0.94rem;
          position: absolute;
          right: 0;
          top: 1.2rem;
          animation: handAni 1s linear infinite;
        }
      }
      &-item3 {
        width: 2.1rem;
        height: 3.5rem;
        position: relative;
        .clickDom {
          width: 102%;
          height: 2rem;
          position: absolute;
          top: 0.3rem;
        }
        .ball {
          width: 100%;
          height: 100%;
          transform-origin: 0 50%;
          animation: ballThreeAni 7s linear infinite;
        }
        .hand {
          width: 0.82rem;
          height: 0.94rem;
          position: absolute;
          right: 0;
          top: 1.2rem;
          animation: handAni 1s linear infinite;
        }
      }
    }
    &-count {
      width: 2.44rem;
      height: 0.44rem;
      background: rgba(255, 255, 255, 0.83);
      border-radius: 0.22rem;
      font-size: 0.24rem;
      color: #ff5c18;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: relative;
      top: -0.5rem;
    }
  }
  @keyframes priceBtnAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ballOneAni {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes ballTwoAni {
    0% {
      transform: translateY(0) rotate(0deg);
    }
    25% {
      transform: translateY(5%) rotate(-8deg);
    }
    50% {
      transform: translateY(15%) rotate(0deg);
    }
    75% {
      transform: translateY(5%) rotate(8deg);
    }
    100% {
      transform: translateY(0) rotate(0deg);
    }
  }
  @keyframes ballThreeAni {
    0% {
      transform: translateY(0) rotate(0deg);
    }
    25% {
      transform: translateY(5%) rotate(-5deg);
    }
    50% {
      transform: translateY(10%) rotate(0deg);
    }
    75% {
      transform: translateY(5%) rotate(5deg);
    }
    100% {
      transform: translateY(0) rotate(0deg);
    }
  }
  @keyframes handAni {
    0% {
      transform: scale(1);
      right: 0;
      top: 1.2rem;
    }
    50% {
      transform: scale(0.9);
      right: -0.1rem;
      top: 1.3rem;
    }
    100% {
      transform: scale(1);
      right: 0;
      top: 1.2rem;
    }
  }
</style>
