<template>
  <div :class="['share-landing-v4', isRedBagShow ? 'redBagBg' : '']">
    <Header :titleUrl="require('@/assets/images/share-landing-v4/invite-bg.png')"></Header>
    <div v-if="!isRedBagShow" class="share-landing-v4-container">
      <BookContent :top="1.6" :left="1.6"></BookContent>
      <div class="share-landing-v4-container-btn">
        <img
          id="open-bag-btn"
          @click="openRedBag"
          class="btn"
          src="@/assets/images/share-landing-v4/btn-1.png"
          alt="btn"
        />
        <img
          @click="copyDownload('#v4-giveMe-btn-one', '没问题', '落地页搞笑')"
          id="v4-giveMe-btn-one"
          class="btn"
          src="@/assets/images/share-landing-v4/btn-2.png"
          alt="btn"
        />
        <img
          :class="['hand', currentClick === 1 ? 'clickOne' : '']"
          src="@/assets/images/share-landing-v4/pointer.png"
          alt="hand"
        />
      </div>
    </div>
    <!--      红包-->
    <RedBag :copyDownload="copyDownload" v-else></RedBag>
    <!--      底部评论区-->
    <BotContent :isNeedSetBg="false"></BotContent>
    <button
      id="v4-download-btn"
      @click="copyDownload('#v4-download-btn', 'button下载', '落地页搞笑')"
      :data-clipboard-text="clipboardText"
      class="share-landing-v4-btn__download"
    >
      点击下载快音 一起听歌赚钱
    </button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import BotContent from '@/views/Welfare/Fission/ShareLanding/ShareLandingV3/components/BotContent';
  import Header from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/Header';
  import RedBag from '@/views/Welfare/Fission/ShareLanding/ShareLandingV4/components/RedBag';
  import BookContent from '@/views/Welfare/Fission/ShareLanding/ShareLandingV4/components/BookContent';
  export default {
    name: 'ShareLandingV4',
    components: { RedBag, BotContent, Header, BookContent },
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        isRedBagShow: false,
        currentClick: 2,
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText']),
    },
    methods: {
      /**
       * @description 打开红包页
       */

      openRedBag() {
        this.$track({
          page_title: `落地页搞笑`,
          element_name: '福利',
        });
        this.currentClick = 1;
        setTimeout(() => {
          this.isRedBagShow = true;
        }, 500);
      },
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v4 {
    user-select: none;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: relative;
    background: url('~@/assets/images/share-landing-v4/bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    &.redBagBg {
      background: url('~@/assets/images/share-landing-v4/bg-2.png') no-repeat no-repeat;
      background-size: 100% 100%;
    }
    &-container {
      height: 5.3rem;
      position: relative;
      width: 100%;
      &-btn {
        position: absolute;
        bottom: -0.7rem;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-around;
        padding: 0 0.7rem;
        .btn {
          height: 0.94rem;
          width: 2.8rem;
        }
        .hand {
          width: 1rem;
          height: 1rem;
          position: absolute;
          top: 0.4rem;
          right: 0.4rem;

          animation: handAni 1s linear infinite;
          &.clickOne {
            top: 0.4rem;
            right: 3.4rem;
          }
        }
      }
    }

    &-btn__download {
      z-index: 2;
      width: 6.44rem;
      height: 1.1rem;
      background: linear-gradient(180deg, #fee696 0%, #fdd687 13%, #ffb668 92%, #ffcb89 100%);
      box-shadow: 0 0.07rem 0.11rem 0 rgba(236, 170, 40, 0.66);
      border-radius: 0.55rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.44rem;
      font-weight: bold;
      color: #fff;
      position: fixed;
      bottom: 0.6rem;
      border: none;
      animation: downloadBtnAni 1.5s linear infinite;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  @keyframes downloadBtnAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes handAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
