// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/share-landing-v1/red-bag-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".share-landing-v3-container-redBag[data-v-94f0efc6]{height:5.3rem;position:relative}.share-landing-v3-container-redBag .redBag-cont[data-v-94f0efc6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;width:7rem;height:6.9rem}.red-bag[data-v-94f0efc6]{margin:.2rem 0 .5rem 0;display:flex;align-items:center;flex-direction:column;position:relative}.red-bag .close[data-v-94f0efc6]{width:.48rem;height:.48rem;position:absolute;right:.92rem;top:.4rem}.red-bag-maxH[data-v-94f0efc6]{width:1.4rem;height:.4rem;display:flex;justify-content:center;align-items:center;color:#fa572a;font-size:.24rem;margin-top:.5rem}.red-bag-num[data-v-94f0efc6]{font-size:.52rem;color:#ff3700}.red-bag-info[data-v-94f0efc6],.red-bag-num[data-v-94f0efc6]{font-weight:700;margin-top:.1rem}.red-bag-info[data-v-94f0efc6]{display:flex;align-items:center;color:#ff4a4a;font-size:.28rem}.red-bag-info img[data-v-94f0efc6]{width:.24rem;height:.29rem;margin-right:.2rem}.red-bag-money[data-v-94f0efc6]{width:2.72rem;height:2.1rem;margin-top:.1rem}.red-bag-get[data-v-94f0efc6]{margin-top:.1rem;position:relative}.red-bag-get-btn[data-v-94f0efc6]{width:4.56rem;height:1.22rem;-webkit-animation:getBtnAni-data-v-94f0efc6 1s linear infinite;animation:getBtnAni-data-v-94f0efc6 1s linear infinite}.red-bag-reduce[data-v-94f0efc6]{width:2.04rem;height:.44rem;border-radius:.22rem;background:hsla(0,0%,100%,.3);display:flex;justify-content:center;align-items:center;font-size:.24rem;color:#fff;font-weight:700;margin-top:.2rem}@-webkit-keyframes getBtnAni-data-v-94f0efc6{0%{transform:scale(1);bottom:-.5rem;right:.3rem}50%{transform:scale(.9)}to{transform:scale(1)}}@keyframes getBtnAni-data-v-94f0efc6{0%{transform:scale(1);bottom:-.5rem;right:.3rem}50%{transform:scale(.9)}to{transform:scale(1)}}", ""]);
// Exports
module.exports = exports;
