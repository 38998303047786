<template>
  <div class="content">
    <div :class="['share-landing-v3', isLuckyRedBagShow ? 'filter' : '']">
      <div ref="sky" class="share-landing-v3-sky"></div>
      <img
        ref="childOne"
        :class="['share-landing-v3-child-one', !isLanding && selectChildIndex !== 1 ? 'flyAni' : '']"
        src="@/assets/images/share-landing-v3/child-one.png"
        alt="child"
      />
      <img
        ref="childTwo"
        :class="['share-landing-v3-child-two', !isLanding && selectChildIndex !== 2 ? 'flyAni' : '']"
        src="@/assets/images/share-landing-v3/child-two.png"
        alt="child"
      />
      <img
        ref="childThree"
        :class="['share-landing-v3-child-three', !isLanding && selectChildIndex !== 3 ? 'flyAni' : '']"
        src="@/assets/images/share-landing-v3/child-three.png"
        alt="child"
      />
      <img ref="yun" class="share-landing-v3-yun" src="@/assets/images/share-landing-v3/yun.png" alt="yun" />

      <div class="share-landing-v3-container">
        <Header :titleUrl="require('@/assets/images/share-landing-v1/invite-font.png')"></Header>
        <div v-if="!isRedBagShow" class="share-landing-v3-container-box__btn">
          <img
            id="fly-landing"
            v-if="!isLanding && (inviteLandingAbV2 === 'd' || inviteLandingAbV2 === 'g')"
            @click="startLanding"
            class="share-landing-v3-container-btn"
            :src="
              inviteLandingAbV2 === 'd'
                ? require('@/assets/images/share-landing-v3/btn-fly.png')
                : require('@/assets/images/share-landing-v3/btn-fly-2.png')
            "
            alt="btn"
          />
          <img
            v-if="!isLanding && (inviteLandingAbV2 === 'd' || inviteLandingAbV2 === 'g')"
            class="hand"
            src="@/assets/images/share-landing-v2/hand@2x.png"
            alt="hand"
          />
        </div>
        <!--      红包-->
        <template v-else>
          <RedBag v-if="inviteLandingAbV2 === 'd' || inviteLandingAbV2 === 'e'" :copyDownload="copyDownload"></RedBag>
        </template>

        <!--      底部评论区-->
        <BotContent :isLanding="isLanding"></BotContent>
        <button
          id="v3-download-btn"
          @click="copyDownload('#v3-download-btn', '固定下载button', pageTitle)"
          :data-clipboard-text="clipboardText"
          class="share-landing-v3-btn__download"
        >
          点击下载快音 一起听歌赚钱
        </button>
        <div
          id="v3-auto-download"
          ref="autoDownload"
          @click="copyDownload('#v3-auto-download', '自动跳转', pageTitle)"
          :data-clipboard-text="clipboardText"
        ></div>
      </div>
    </div>
    <LuckyRedBag
      :resetGame="resetGame"
      :copyDownload="copyDownload"
      v-if="inviteLandingAbV2 === 'g' && isLuckyRedBagShow"
    ></LuckyRedBag>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import BotContent from '@/views/Welfare/Fission/ShareLanding/ShareLandingV3/components/BotContent';
  import Header from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/Header';
  import RedBag from '@/views/Welfare/Fission/ShareLanding/ShareLandingV3/components/RedBag';
  import LuckyRedBag from '@/views/Welfare/Fission/ShareLanding/ShareLandingCom/LuckyRedBag';
  export default {
    name: 'ShareLandingV1',
    components: { RedBag, BotContent, Header, LuckyRedBag },
    props: {
      copyDownload: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        isLanding: false,
        selectChildIndex: 0,
        isRedBagShow: false,
        isLuckyRedBagShow: false,
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText', 'inviteLandingAbV2', 'pageTitle']),
    },
    methods: {
      /**
       * @description 开始降落
       */

      startLanding() {
        this.$refs.sky.addEventListener('transitionend', this.transitionendListener);
        this.$refs.sky.style.transition = 'top 2s';
        this.$refs.yun.style.transition = 'top 2s';
        this.$refs.childTwo.transition = 'top 2s';
        this.$refs.childThree.transition = 'top 2s';
        this.$refs.childOne.transition = 'top 2s';

        this.$track({
          page_title: this.pageTitle,
          element_name: this.inviteLandingAbV2 === 'd' ? '点击降落' : '试试手气',
        });

        let arr = [1, 2, 3];
        let index = Math.floor(Math.random() * arr.length);

        this.selectChildIndex = arr[index];
        this.isLanding = true;
        document.getElementById('copy-code').click(); //复制邀请码
        setTimeout(() => {
          this.$refs.sky.style.top = `-8rem`;
          this.$refs.yun.style.top = `-1.5rem`;
          switch (this.selectChildIndex) {
            case 1:
              this.$refs.childTwo.style.top = `-4.4rem`;
              this.$refs.childThree.style.top = `-5.5rem`;
              this.$refs.childOne.style.top = `3.5rem`;
              break;
            case 2:
              this.$refs.childOne.style.top = `-5.5rem`;
              this.$refs.childThree.style.top = `-5.5rem`;
              this.$refs.childTwo.style.top = `3.5rem`;
              break;
            case 3:
              this.$refs.childTwo.style.top = `-4.4rem`;
              this.$refs.childOne.style.top = `-5.5rem`;
              this.$refs.childThree.style.top = `3.3rem`;
              break;
          }
        }, 100);
      },
      resetGame() {
        this.isLanding = false;
        this.$refs.sky.removeAttribute('style');
        this.$refs.yun.removeAttribute('style');
        this.$refs.childTwo.removeAttribute('style');
        this.$refs.childThree.removeAttribute('style');
        this.$refs.childOne.removeAttribute('style');
      },
      transitionendListener() {
        this.$refs.yun.style.display = 'none';
        setTimeout(() => {
          this.$refs.sky.removeEventListener('transitionend', this.transitionendListener);
          // 设置高斯模糊
          this.$refs.sky.style.filter = 'blur(10px)';
          this.$refs.childTwo.style.filter = 'blur(10px)';
          this.$refs.childOne.style.filter = 'blur(10px)';
          this.$refs.childThree.style.filter = 'blur(10px)';
          if (this.inviteLandingAbV2 === 'g') {
            this.isLuckyRedBagShow = true;
          } else {
            // 改变显示红包
            this.isRedBagShow = true;
            // 无行为2秒后下载
            setTimeout(() => {
              this.$refs.autoDownload.click();
            }, 2000);
          }
        }, 1000);
      },
    },
    mounted() {
      // 监听动画执行完毕
      // this.$refs.sky.addEventListener('transitionend', this.transitionendListener);

      // e 组 间隔两秒直接降落
      this.inviteLandingAbV2 === 'e' &&
        setTimeout(() => {
          this.startLanding();
        }, 2000);
    },
  };
</script>

<style scoped lang="less">
  .share-landing-v3 {
    user-select: none;
    &.filter {
      filter: blur(10px);
    }
    position: relative;
    &-container {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      &-box__btn {
        height: 5.3rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        .hand {
          width: 0.82rem;
          height: 0.94rem;
          position: absolute;
          right: 0;
          top: 4.5rem;
          animation: handAni 1s linear infinite;
        }
      }

      &-btn {
        width: 4.1rem;
        height: 1.2rem;
      }
    }
    &-sky {
      //transition: top 2s;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: url('~@/assets/images/share-landing-v3/bg.png') no-repeat no-repeat;

      background-size: 100% 100%;
      z-index: -3;
    }
    &-yun {
      //transition: top 2s;
      width: 100%;
      height: 3.16rem;
      position: absolute;
      top: 6.6rem;
      left: 0;
      right: 0;
      z-index: -2;
    }
    &-child-one {
      z-index: -1;
      //transition: top 2s;
      width: 1.87rem;
      height: 2.14rem;
      position: absolute;
      top: 2.5rem;
      left: 1rem;
      &.flyAni {
        animation: childOneFly 2s linear infinite;
      }
    }
    &-child-two {
      z-index: -1;
      //transition: top 2s;
      width: 2.7rem;
      height: 2.56rem;
      position: absolute;
      top: 3.6rem;
      left: 2.5rem;
      &.flyAni {
        animation: childTwoFly 2s linear infinite;
        animation-delay: 1s;
      }
    }
    &-child-three {
      z-index: -1;
      //transition: top 2s;
      width: 1.9rem;
      height: 1.86rem;
      position: absolute;
      top: 2.5rem;
      left: 4.9rem;
      &.flyAni {
        animation: childThreeFly 2s linear infinite;
      }
    }

    &-btn__download {
      z-index: 2;
      width: 6.44rem;
      height: 1.1rem;
      background: linear-gradient(180deg, #bbf4ff 0%, #63e0ff 13%, #2989ff 93%, #8bbbff 100%);
      box-shadow: 0 0.07rem 0.11rem 0 #3a2ff1;
      border-radius: 0.55rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.44rem;
      font-weight: bold;
      color: #fff;
      position: fixed;
      bottom: 0.6rem;
      border: none;
      animation: downloadBtnAni 1.5s linear infinite;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  @keyframes downloadBtnAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes childOneFly {
    0% {
      top: 2.5rem;
      left: 1rem;
    }
    50% {
      top: 2.6rem;
      left: 1.05rem;
    }
    100% {
      top: 2.5rem;
      left: 1rem;
    }
  }
  @keyframes childTwoFly {
    0% {
      top: 3.6rem;
      left: 2.5rem;
    }
    50% {
      top: 3.7rem;
      left: 2.55rem;
    }
    100% {
      top: 3.6rem;
      left: 2.5rem;
    }
  }
  @keyframes childThreeFly {
    0% {
      top: 2.5rem;
      left: 4.9rem;
    }
    50% {
      top: 2.6rem;
      left: 4.95rem;
    }
    100% {
      top: 2.5rem;
      left: 4.9rem;
    }
  }
  @keyframes handAni {
    0% {
      transform: scale(1);
      right: 0;
      top: 4.5rem;
    }
    50% {
      transform: scale(0.9);
      right: -0.1rem;
      top: 4.6rem;
    }
    100% {
      transform: scale(1);
      right: 0;
      top: 4.5rem;
    }
  }
</style>
