<template>
  <div class="dl-btn">
    <button
      id="dl-btn"
      class="button vivify pulsate infinite"
      @click="copyDownload('#dl-btn', '点击下载')"
      :data-clipboard-text="clipboardText"
    >
      <i class="coin" />
      点击下载快音，让音乐更懂你
    </button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    components: {},
    props: {
      copyDownload: {
        type: Function,
      },
    },
    data() {
      return {};
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText']),
    },
    mounted() {},
  };
</script>

<style lang="less" scoped>
  .dl-btn {
    padding: 0.24rem 0.42rem;
    button {
      width: 6.46rem;
      height: 0.8rem;
      background: linear-gradient(192deg, #f7c300 0%, #ff3666 100%);
      border-radius: 0.4rem;
      border: 2px solid rgba(247, 212, 112, 1);
      font-size: 0.32rem;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      i.coin {
        display: inline-block;
        width: 0.44rem;
        height: 0.44rem;
        border-radius: 50%;
        background: url('https://static1.kaixinyf.cn/img/lza5fe4408cd131b664544735.png') no-repeat center center;
        background-size: cover;
        vertical-align: middle;
        margin-right: 0.08rem;
      }
    }
  }
</style>
